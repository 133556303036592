@import "./mixins";

*,
*::after,
*::before {
    // hard reset on the styles
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    // This defines what 1 rem is, in this case 10px
    font-size: 62.5%;
    overflow-x: hidden;
}

body {
    box-sizing: border-box;
    overflow-x: hidden;

}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    html:focus-within {
        scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}