.gdpr-main {
    width: 100%;
    margin: 0 auto;
}

.gdpr-heading {
    text-align: center;
    background-image: linear-gradient(to right bottom, rgba(126, 213, 111, 0.7), rgba(40, 180, 133, 0.7));
    border-radius: 5px;
    padding: 0.5vw;
}

.gdpr-heading .cont-row {
    width: 100%; 
    margin-top: 1vw;
    margin-bottom: -2.5vw;
    margin-left: 3vw;
}

.gdpr-heading .glitch-text_wrap {
    width: 28vw;
    margin-bottom: 0;
    text-align: left;
}

.gdpr-heading .glitch-text_wrap .glitch-text{
    font-size: 3vw;
    font-family: 'Bona Nova SC';
    letter-spacing: 0.5;
}

.sections {
    padding: 0 5%;
}

.gdpr-heading p {
    font-size: 1.6vw;
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    color: #fff;
}

.gdpr-main section {
    margin-top: 4vw;
}

.gdpr-main h1,
.gdpr-main section h2,
.gdpr-main section h3 {
    font-size: 1.6vw;
    font-family: "Ubuntu", sans-serif;
    font-weight: 600;
    
    background-image: linear-gradient(to right, #7ed56f, #28b485);
    -webkit-background-clip: text;
    background-clip: text;
    background-clip: text;
    color: transparent;

    margin-bottom: 2vw;
}

.gdpr-main h1 {
    font-size: 1.9vw;
    margin-top: 4vw;
    margin-bottom: 6vw;
    text-align: center;
    border-top: 1px solid #838181;
}

.gdpr-main section h3 {
    font-size: 1.4vw;
}

.gdpr-main section p,
.gdpr-main section li  {
    font-size: 1.2vw;
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    margin-top: 1vw;
}

.gdpr-main section li::marker {
    color: #28b485;
    font-size: 1.5vw;
}

.gdpr-main section span {
    background-image: linear-gradient(to right, #7ed56f, #28b485);
    -webkit-background-clip: text;
    background-clip: text;
    background-clip: text;
    color: transparent;
}

.gdpr-main section ul {
    margin-top: 1vw;
    margin-left: 4vw;
    margin-bottom: 1vw;
}

.gdpr-main section a {
    color: #28b485;
}

.gdpr-main section div p,
.gdpr-main section div span,
.gdpr-main section div a,
.gdpr-main section div li {
    font-style: italic;
}

.footer-cont {
    margin-top: 2vw;
}

@media screen and (max-width: 430px) {
    .gdpr-heading .cont-row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 6vw;
        margin-top: 4vw;
        margin-bottom: -5vw;
    }
  
    .gdpr-heading .glitch_wrap .header__logo {
        transform: scale(1.2);
    }

    .gdpr-heading .glitch-text_wrap {
        width: 41vw;
        margin-top: 2vw;
    }

    .gdpr-heading .glitch-text_wrap .glitch-text {
        height: max-content;
        font-size: 4vw;
    }

    .gdpr-main section h2 {
        font-size: 3.2vw; 
    }

    .gdpr-main h1 {
        font-size: 3.5vw;
    }

    .gdpr-main section h3 {
        font-size: 2.5vw;
    }

    .gdpr-main section p,
    .gdpr-main section li  {
        font-size: 2.5vw;
        margin-top: 1.2vw;
    }

    .gdpr-main section li::marker {
        font-size: 2.7vw;
    }
}

