.profile-summary-cont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 8vw;
    margin-bottom: 1vw;
    margin-left: 3vw;
}

.user-details {
    margin-top: 1vw;
    margin-left: 1vw;
}

.top-details {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1vw;
}

.profile-upload-cont {
    background-color: #EDEDED;
    box-shadow: 0 0 5px inset rgba(0,0,0,0.5);

    border-radius: 50%;
    width: 8vw;
    height: 8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.profile-upload-cont:hover {
    border-color: rgba(40, 180, 133, 0.7);
}

.user-name {
    font-family: "Adys Bold", sans-serif;
    font-size: 1.8vw;
    font-weight: 700;
}

.actions {
    display: flex;
    flex-direction: row;
    gap: 1vw;
}

.action-cont {
    /* background-color: rgba(40, 180, 133, 0.7); */
    background-image: linear-gradient(to left, rgba(178, 253, 165, 1), rgba(95, 209, 171, 1));
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    padding: 0 0.5vw;

    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;
}

.action-cont:hover {
    transform: scale(1.1);
    background-color: rgba(40, 180, 133, 0.9);
}

.action-icon {
    font-size: 1.8vw;
    color: rgba(0,0,0,0.8);
    transition: all 0.3s;
}

.action-cont:hover .action-icon{
    color: rgba(255,255,255, 0.85);
    transition: all 0.3s;
}

.user-address {
    font-family: "Adys Regular", sans-serif;
    font-weight: 400;
    font-size: 1.4vw;
}

.hover:hover {
    color:#65C466;
}

.image-upload-icon {
    font-size: 4vw;
    color: #d3d3d3; 
    cursor: pointer;
}

.image-upload-con:hover {
   color: rgba(40, 180, 133, 0.7);
}

@media only screen and (max-width: 600px) {
    .profile-summary-cont {
        margin-top: 11rem;
        margin-bottom: 3rem;
        margin-left: 0;
        width: 100%;
        align-items: center;
    }

    .profile-upload-cont {
        width: 7rem;
        height: 7rem;
    }

    .profile-upload-cont .image-upload-icon {
        width: 3.2rem;
        height: 3.2rem;
    }

    .actions {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        margin-top: -0.4rem;
    }
    
    .action-cont {
        /* background-color: rgba(40, 180, 133, 0.7); */
        background-image: linear-gradient(to left, rgba(178, 253, 165, 1), rgba(95, 209, 171, 1));
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;
        padding: 0 0.6rem;
    
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.5s;
    }
    
    
    .action-icon {
        font-size: 1.9rem;
        color: rgba(0,0,0,0.8);
        transition: all 0.3s;
    }

}
  