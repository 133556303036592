.separator-text-cont {
    text-align: center;
    
    margin-bottom: 2vw;
    margin-top: 13rem;
}

.separator-text-cont p {
    font-family: "Adys Bold", sans-serif;
    font-size: 3vw;
    color: #fff;
}

.horizontal {
    clip-path: polygon(0 19rem, 100% 6rem, 100% 100%, 0 100%);
    padding: 10rem;
}

@media screen and (max-width: 600px) {
    .separator-text-cont {
        width: 60vw;
        font-size: 6vw;
        color: #fff;
        margin-bottom: 10vw;
        margin-top: 5vh;
        margin-left: -5vw;
    }

    .separator-text-cont p {
        font-size: 5.5vw;
        color: #fff;
    }

    .horizontal {
        clip-path: polygon(0 18vh, 100% 6vh, 100% 100%, 0 100%) !important;
        padding: 10rem;
        padding-bottom: 5rem;
    }
}



@media screen and (max-width: 390px) {
    .horizontal {
        clip-path: polygon(0 22vh, 100% 0vh, 100% 100%, 0 100%);
    }
}

@media screen and (max-width: 375px) {
    .horizontal {
        clip-path: polygon(0 19vh, 100% 0vh, 100% 100%, 0 100%);
    }
}