// COLORS
$color-primary: #55c57a;
$color-primary-light: #7ed56f;
$color-primary-dark: #28b485;

$color-grey-dark: #777;
$color-grey-light-1 : #f7f7f7;
$color-grey-light-2 : #eee;
$color-grey-dark-1 : #333;

$color-white: #fff;
$color-black: #000;

//GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-horizontal: 7rem;



//FONT

$default-font-size: 1.6rem;