.modal_backdrop {
    position: fixed;
    top: -10vh;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: rgba(0,0,0,0.5); */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 12;
}

.modal_main {
    width: 40%;
    height: 100%;
    text-align: center;
    overflow: hidden;
}

.modal_container {
    background-color: #F8F8F8;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border: 1px solid #000;
    border-radius: 8px;
    width: 100%;
    margin: 0 auto;
}

.modal_container p {
    color: #000;
    text-align: center;
    font-size: 1.5vw;
    margin-bottom: 1vw;
}

.images_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    margin: 1vw auto;
    width: 100%;
}

.btn-container {
    width: 80%;
    display: flex;
    justify-content: end;
    margin: 1.3vw auto;
}

