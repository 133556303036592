@import "../../../sassStyles/variables";


.section-features {
    padding: 20rem 0;
    background-image: linear-gradient(to right bottom, rgba($color-primary-light, 0.7), rgba($color-primary-dark, 0.7));
    background-size: cover;

    transform: skewY(-7deg);
    margin-top: -10rem;

    &> * {
        transform: skewY(7deg);
    }

}

.feature-box {

    background-color: rgba($color-white, .8);
    font-size: 1.5rem;
    padding: 2.5rem;
    text-align: center;
    border-radius: 3px;
    box-shadow: 0 1.5rem 4rem rgba($color-black, .15);
    transition: all .2s;

    &__icon {
        font-size: 6rem;
        margin-bottom: .5rem;
        display: inline-block;

    }

    &:hover{
        transform: translateY(-1.5rem) scale(1.03);
    }

}

.main-text{
    text-align: center;
    font-size:6rem;
    letter-spacing: 1.5rem;
}


.hidden{
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-100%);
    transition: all .5;
}



.show{
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);

}


.feature-box:nth-child(2){
    transition-delay: 1000ms;
}

.feature-box:nth-child(3){
    transition-delay:  1200ms;
}
 
.feature-box:nth-child(4){
    transition-delay: 1400ms;
}
