.badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #55c57a; /* Customize the badge background color */
  color: white; /* Customize the badge text color */
  padding: 3px 6px;
  border-radius: 50%;
  font-size: 10px;
}
