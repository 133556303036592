.section-features {
  margin-top: 0;
}

.section-features .features-heading {
  text-align: center;
  
  background-image: linear-gradient(to right, #7ed56f, #28b485);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: 2vw;
  margin-top: -3vw;
}

.features_heading-txt {
  font-family: "Adys Bold", sans-serif !important;
  font-weight: 400;
  font-size: 3.5vw;
}

.features_heading_img {
  margin-top: -22vw; 
  width: 36vw; 
  position: relative;
  top: 13.5vw; 
  left: 31.5vw;
}

.feature-box {
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 1.5rem;
  padding: 2.5rem;
  text-align: center;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  transition: all 0.5s;
  cursor: pointer;
}

.feature-box__icon {
  font-size: 4.5vw;
  margin-bottom: 0.5rem;
  display: inline-block;
}
.feature-box:hover {
  transform: translateY(-1.5rem) scale(1.03);
}

.main-text {
  text-align: center;
  font-size: 6rem;
  letter-spacing: 1.5rem;
}

.feature-main-text {
  font-family:"Adys Bold", sans-serif;
  font-weight: 400;
  font-size: 1.5vw;
  margin: 1vw 0;
}

.feature-sub-text {
  font-family: "Adys Regular", sans-serif;
  font-weight: 400;
  font-size: 1.6vw;
}

.hidden_feature {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100%);
  transition: all 0.5;
}

.gradient_green {
  font-family: "Adys Bold", sans-serif;
  background: linear-gradient(to right, rgba(126, 213, 111, 0.7), rgba(40, 180, 133, 0.7));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  font-size: 1.7vw;
}

.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}

.feature-box:nth-child(2) {
  transition-delay: 1000ms;
}

.feature-box:nth-child(3) {
  transition-delay: 1200ms;
}

.feature-box:nth-child(4) {
  transition-delay: 1400ms;
}/*# sourceMappingURL=features.css.map */

/* .row {
  max-width: 114rem;
  margin: 0 auto;
}
.row:not(:last-child) {
  margin-bottom: 8rem;
}
.row::after {
  content: "";
  display: table;
  clear: both;
}
.row [class^=col-] {
  float: left;
}
.row [class^=col-]:not(:last-child) {
  margin-right: 2vw;
}

.row .col-1-of-4 {
  width: calc((100% - 3 * 3vw) / 4);
} */

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  gap: 3vw; 
}

.row .col-1-of-4 {
  width: 20%;
  min-height: 100%;
}

.feature_icon {
  width: 5vw;
}

@media screen and (max-width: 600px) {
  .section-features {
    /* clip-path: polygon(0 29.8vh, 100% 4%, 100% 100%, 0 100%); */
    margin-top: 0rem;
    margin-bottom: 28rem;
    height: max-content;
  }

  .features_heading_img {
    margin-top: -41vw; 
    width: 77vw; 
    position: relative;
    top: 31.5vw; 
    left: 12vw;
  }

  .section-features .features-heading {
    margin-bottom: 8vw;
    margin-top: -3vw;
  }

  .features_heading-txt {
    font-size: 7.4vw;
  }

  .section-features .row{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4vw;

  }

  .feature_icon {
    width: 10vw;
  }

  .row .col-1-of-4 {
    width: calc((100% - 2 * 7vw));
    margin-right: 0;
  }

  .row [class^=col-]:not(:last-child) {
    margin-right: 0;
  }

  .feature-main-text {
    font-weight: 500;
    font-size: 3.8vw;
    margin: 1vw 0;
  }
  
  .feature-sub-text {
    font-weight: 400;
    font-size: 3.2vw;
  }

  .gradient_green {
    font-size: 4vw;
  }
}

