.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 10;
}

.info-popup-container{
    width: 400px;
    padding: 5px;
    height: 280px;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,0.7);
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.7);
    margin: 0 auto;
}

.close-icon-container {
    width: 100%;
    text-align: end;
}

.info-popup-content {
    margin-top: 4rem;
    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}



.info-popup-content .content-image {
    transform: scale(0.7);
    bottom: 4.5rem;
    position: absolute;
}

.info-popup-content h4 {
    font-family: "Adys Bold";
    font-weight: 500;
    font-size: 2.1rem;
    color: rgba(0, 0, 0, 0.8);
}

.info-popup-content div {
    text-align: center;
}

.info-popup-content div p {
    font-family: "Adys Regular";
    font-weight: 400;
    font-size: 1.7rem;
    margin-top: 0.2rem;
    color: rgba(0, 0, 0, 0.8);
}

.info-popup-content .module-name {
    font-family: "Adys Bold";
    font-weight: 500;
    font-size: 2.3rem;
    color: rgba(40, 180, 133, 1);
}

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .info-popup-container {
        transform: scale(0.85);
        width: 100%;
    }

    .info-popup-content .module-name {
        font-family: "Adys Bold";
        font-weight: 500;
        font-size: 2.3rem;
        color: rgba(40, 180, 133, 1);
    }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
  
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
  
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
  
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
  
  }