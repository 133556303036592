.section-separator {
    padding: 16rem 0; 
    background-image: linear-gradient(to right bottom, rgba(126, 213, 111, 0.7), rgba(40, 180, 133, 0.7));
    background-size: cover;
    clip-path: polygon(0 25.2vh, 100% 4%, 100% 50vh, 0 100%);
}

.features-heading {
    text-align: center;
    margin-bottom: 2vw;
    margin-top: 6vw;
}

.features-heading p {
    font-family: "Adys Bold", sans-serif;
    font-size: 3vw;
    color: #fff;
}

@media screen and (max-width: 600px) {
   .section-separator {
        

        padding: 16rem 0;
        background-image: linear-gradient(to right bottom, rgba(126, 213, 111, 0.7), rgba(40, 180, 133, 0.7));
        background-size: cover;
        clip-path: polygon(0 25.2vh, 100% 4%, 100% 36rem, 0 100%);
    }

    .features-heading p{
        font-size: 6vw;
    }

    .features-heading {
        font-size: 6vw;
        color: #fff;
        margin-bottom: 0;
        margin-top: 10rem;
    }
}
