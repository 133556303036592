.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.form-card {
  width: 100%;
  max-width: 72vw;
  padding: 2rem;
  background-color: #fff;
  border: 0.1rem solid #eaeaea;
  border-radius: 0.8rem;
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.1);
}

.arrows {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1vw;

  margin-top: 5vw;
  margin-left: -3.6vw;
}

.arrow {
  font-size: '14px';
}

.arrow_text {
  /* color: #28b485; */
}

.buttons-cont {
  display: flex; 
  flex-direction: row;
  justify-content: end;
  width: 100%;
}

.weight-height-cont {
  display: flex;
  flex-direction: row;
  gap: 1vw;
}

.animated-dog-cont {
  margin-bottom: -5vw;
  z-index: 5;
}

.form-card__header {
  text-align: center;
  letter-spacing: 3px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #65C466;
  font-size: 2rem;
}

.city-region-cont {
  display: flex;
  flex-direction: row;
  gap: 1vw;
}

.breed-color_cont {
  display: flex; 
  flex-direction: row;
  gap: 1vw;
}


.vaccinated {
    letter-spacing: 1px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dog-main-info_cont {
 
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2.5vw;
}

.name-breed_cont {
  width: 54%;
  display: flex;
  flex-direction: column;
  margin-left: 2vw;
  margin-right: 3vw;
}

.upload_cont {
  border: 3px solid #7a7a83;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13vw;
  height: 12vw;
  cursor: pointer;
  margin-right: -2vw;
  margin-left: -2vw;
}

.upload_cont:hover {
  border-color: #65C466;
}

.dog-details_cont {
  display: flex;
  flex-direction: row;
  gap: 1vw;
}

.date-cont {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.sex-age_cont {
  width: 82%;
}

.breed-checkboxes_cont {
  display: flex;
  flex-direction: row;
  margin-top: 1.4vw;
  gap: 2vw;
}

.originalBreed_cont {
  width: 28vw;
  height: 15vw;
  border-radius: 25px;
  background-color:  #c7c7c7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1vw 0;
  gap: 1vw;
}

.upload-breed_cont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12vw;
  height: 11vw;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
}
