.landing-container{
    padding: 0;
}

.features-container {
    margin-top: -49vh;
}

@media screen and (max-width: 430px) {
   .features-container {
        margin-top: -47vh;
   }
}