@font-face {
    font-family: 'Adys Regular'; /* Name your font family */
    src: url('../../../fonts/ADYS-Regular V5-5.ttf'); /* Locate the .ttf file within your directory*/
}

@font-face {
    font-family: 'Adys Bold'; /* Name your font family */
    src: url('../../../fonts/ADYS-Bold V5-5.ttf'); /* Locate the .ttf file within your directory*/
}

.show-emailed {
    transition: all 0.5s;
    transform: scale(1);
}

.emailed-message-cont {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem;
}

.show {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
}

.emailed-message-cont.error-message {
    background-color: #ffe5e5; 
}

.emailed-message-cont.success-message {
    background-color: #E7F1E7; 
}

.emailed-cont .emailed-icon {
    font-size: 10vw; 
    color: rgba(126, 213, 111, 0.7);
    margin-bottom: 2vw;
}

.emailed-cont .error-icon {
    font-size: 7vw; 
    color: #900B1A;
}

.heading-sub-text {
    font-family: "Adys Regular", sans-serif;
    font-weight: 400;
    font-size: 1.5vw;
}

.emailed-message-cont .error_txt {
    background-image: linear-gradient(to right, #ce1126, #900B1A);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.emailed-message-cont .success_txt {
    background-image: linear-gradient(to right, #7ed56f, #28b485);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.contacts_page_container {
    width: 80%;
    height: 100%;
    margin: 10vw auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contacts_page_container p {
    font-family: "Adys Regular", sans-serif;
    font-size: 3vw;
    background-image: linear-gradient(to right, #7ed56f, #28b485);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.contact_form {
    width: 36%;
    height: auto;
    padding: 3rem;
    padding-top: 0;
}

.contact_form .form-button {
    width: 100%; 
    padding: 1rem 0.5rem;
    font-size: 1.5rem;
    font-family: "Adys Bold", sans-serif;
    margin-top: -2vw;
    background-color: #65C466; 
    margin: 2vw 0;
}

.socials_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2vw;

    margin-top: 1.5rem;
}

.social_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5vw;
    border: 1px solid rgba(0,0,0,0.3);
    border-radius: 8px;
    box-shadow: 0 1px 2px #0000004d, 0 1px 10px #00000038;

    padding: 0.8vw;
    transition: all 0.3s;
    cursor: pointer;
}

.social_card:hover {
    transition: all 0.3s;
    box-shadow: 0 1px 10px #0000004d, 0 2px 14px #00000038;
    transform: scale(1.05);
}

.social_card p {
    font-family: "Adys Bold", sans-serif;
    color: rgba(0,0,0,0.6);
    font-size: 1.3vw;
    transition: all 0.3s
}

.social_card .social_icon {
    font-size: 3rem;
    filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.5));
    transition: all 0.3s;
}

.social_card:hover p{
    transition: all 0.3s;
    background-image: linear-gradient(to right, #7ed56f, #28b485);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.social_card:hover .social_icon{
    transition: all 0.3s;
    color: #7ed56f;
}

.socials-message {
    font-size: 2vw;
    text-align: center;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .contacts_page_container {
        margin-top: 10rem;
    }

    .contacts_page_container p {
        font-size: 3rem;
    }

    .contact_form {
        width: 100%;
        margin-top: 2rem;
    }

    .contacts_page_container .socials-message {
        font-size: 2rem;
    }

    .socials_container .social_card p {
        font-size: 1.3rem;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}