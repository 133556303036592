* {
    font-family: "Adys Regular", sans-serif;
    font-weight: 400;
}

.card_containter {
    width: 50vw;
    height: auto;
    margin: 0 auto;
    margin-top: 1vw;
    margin-bottom: 1vw;
    border-radius: 25px;
    

    padding: 1vw 0;
}

.dark_theme {
    background-color: #191919;
    box-shadow: 0 1px 10px rgba(255, 255, 255, 0.6), 0 2px 14px rgba(255, 255, 255, 1);
}

.light_theme {
    background-color: #fff;
    box-shadow: 0 1px 10px #0000004d, 0 2px 14px #00000038;
}

.card-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dog_image {
    width: 25%;
    border-radius: 50%;
}

.dog-breed {
    font-size: 1.5vw;
}

.dog-details-container {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dog-details-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2vw;
    margin-left: -12vw;
}

.dog-name {
    font-size: 2vw;
    font-weight: 700;
}

.dog-details-container .main-container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 80%;
    margin-top: 2vw;
    margin-left: -7vw;
}

.left-container {
    width: 50%;
    text-align: left;
}

.right-container {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.dog-color {
}

.checbox-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
}

.buttons {
    margin-top: 1vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1vw;
}

.edit-btn {
    /* background-color: rgba(40, 180, 133, 0.7); */
    background-image: linear-gradient(to left, rgba(178, 253, 165, 1), rgba(95, 209, 171, 1));
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    padding: 0.35vw 0.4vw;
    margin-left: 1vw;
    margin-bottom: 0.5vw;

    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;
}   

.edit-btn:hover {
    transform: scale(1.1);
    background-color: rgba(40, 180, 133, 0.9);
}

.edit-btn-icon {
    transition: all 0.3s;
}

.edit-btn:hover .edit-btn-icon {
    transition: all 0.3s;
    color: rgba(255,255,255, 0.9);
}

.profile-data-row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3vw;
}

.profile-data-row .profile-data-icon {
    font-size: 1.6vw;
    margin-bottom: 0.4vw;
    color: rgba(0,0,0,0.5);
}

.profile-data-row p {
    font-size: 1.3vw;
}

.color-indicator {
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    margin-right: 0.4rem;
    /* margin-top: -0.5rem;*/ 
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .card_containter {
        width: 100%;
        margin-bottom: 1.5rem;
    }

    .dog-name {
        font-size: 1.4rem;
    }

    .dog-breed {
        font-size: 1.3rem;
    }

    .edit-btn {
        border-radius: 50%;
        padding: 0.4rem;
        margin-left: 1vw;
        margin-bottom: 0.2rem;
    }

    .profile-data-row .profile-data-icon {
        font-size: 1.3rem;
        margin-bottom: 0.4vw;
    }

    .profile-data-row p {
        font-size: 1.1rem;
    }

    .color-indicator {
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        margin-left: 0.05rem;
        margin-right: 0.4rem
    }

    .right-container {
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: end;
    }

    .left-container {
        width: 70%;
    }

    .right-container {
        align-items: start;
    }

    .checbox-container p {
        font-size: 1rem;
    }

    .profile-data-row {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.2rem;
        margin-bottom: 0.2rem;
    }
 
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}