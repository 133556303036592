/* 
- phone 
- tablet-portrait
- tablet-landscape
- big-desktop
*/

body {
  padding-right: 0 !important;
}

.header {
  width: 100%;
  height: 100vh;
  /* background-image: linear-gradient(to right, rgba(126, 213, 111, 0.7), rgba(40, 180, 133, 0.7)), url(../../../assets/human-and-dog2.jpg); */
  background-image: linear-gradient(to right, rgba(126, 213, 111, 0.7), rgba(40, 180, 133, 0.7));

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 73.8vh, 0 96.4%);
  clip-path: polygon(0 0, 100% 0, 100% 73.8vh, 0 96.4%);

  position: relative;
}

.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0.15;
  overflow: hidden;
}
.bg-video__content {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.header__text-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  height: max-content;
  margin-top: -2vw;
}

.cont-row {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
}

.header-nav {
  width: 100%;
  margin-top: 1vw;
  margin-left: 3vw;

  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
}

.text-logo-cont {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  gap: 1vw;
}

.header-nav-links {
  width: 45%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3vw;
  margin-top: 2.4vw;
}

.navigation-item {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
}

.navigation-item p {
  color: rgba(0,0,0,0.8);
}

.navigation-item:hover {
  border-bottom: 2px solid #fff;
}


.header-nav-links p,
.header-nav-links a {
  font-family: "Adys Bold", sans-serif;
  font-size: 1.7vw;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}

/* .header-nav-links a:hover {
  text-decoration: underline;
  text-underline-offset: 6px;
} */

.heading-primary {
  margin-top: 0vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.heading-primary--sub {
  letter-spacing: 0.3rem;

}

.grop {
  font-size: 3rem
}

.header__logo-box {
  position: absolute;
  top: 4rem;
  left: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1vw;
}

.header__logo {
  width: 56%;
  height: 56%;
  color: #fff;
}

.glitch-text_wrap{
  overflow: hidden;
  position: relative;
  letter-spacing: 0.5rem;
  margin-bottom: 7vw;

  width: 40%;
  height: 10vh;
  font-size: 3vw;
  margin-top: 1vw;
}

.glitch-text {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;

  /* font-family: 'Bona Nova SC'; */
  font-family: "Adys Bold", sans-serif;
  letter-spacing: 0.5;
 /*  background: -webkit-linear-gradient(#282D37, #797C7D);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: #fff;
/*   text-shadow: 1px 1px 1px #282D37;
 */
  display: block;
  letter-spacing: 0.5rem;

  font-size: 3.5vw;
  font-weight: 400;
  animation: moveInLeft 1s ease-out;

}

.glitch-text:before,
.glitch-text:after {
  content: attr(data-text);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  transform: translateX(2px);
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  
  background-blend-mode: difference;
}

.fast-anim:before, 
.fast-anim:after{
  animation-duration: 4s;
  transform: translateX(2px);
}

.slow-anim:before, 
.slow-anim:after{
  animation-duration: 5s;
  transform: translateX(1px);
}

.glitch-text:before {
  animation-name: glitchAnim1;
}

.glitch-text:after {
  animation-name: glitchAnim2;
}

.glitch_wrap {
  overflow: hidden;
  position: relative;
  width: 9.4vw;
  height: 9.4vw;

  margin-bottom: 0.3vw;
  cursor: pointer;
  transition: all 0.2s;
  animation: moveInBottom 1s ease-out;
}

.glitch{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: url('../../../assets/paw-white.png') no-repeat center center;
  /* -webkit-filter: drop-shadow(2px 2px 2px #fff); */
  /* filter: drop-shadow(3px 3px 3px #fff); */
  background-size: cover;
  transform: scale(1.2);
}

.glitch:before,
.glitch:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  transform: translateX(2px);
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  
  background-blend-mode: difference;
}

.glitch:before {
  animation-name: glitchAnim1;
}

.glitch:after {
  animation-name: glitchAnim2;
}

/* .logo_txt {
  font-size: 9vw;
  background: linear-gradient(to right, #151515, #3F3F3F);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 1px 1px 1px #fff;

}  */

.text-underline {
  /* color: #fff; */
  color: rgba(40, 180, 133, 0.7);
  background-color: transparent;
  cursor: pointer;
  font-size: 3.4vw;
  position: relative;
}

/* .text-underline:hover {
  color: rgba(40, 180, 133, 0.7);
}
 */
.green {
  color: rgba(40, 180, 133, 0.7);
}

.green:hover {
  color: #fff
}

.green:before {
  content: "";
  width: 104%;
  height: 0.15em;
  background-image: linear-gradient(to right, #7ed56f, #28b485);
  position: absolute;
  bottom: -2px;
  left: -0.5vw;
  transition: all 0.5s;
}

.green:hover:before {
  height: 100%;
  z-index: -1;
}


.text-underline:before {
  content: "";
  width: 106%;
  /* height: 0.15em; */
  height: 100%;
  z-index: -1;
  background: #fff;
  border-radius: 5px;
  position: absolute;
  bottom: -2px;
  left: -0.5vw;
  transition: all 0.5s;
}

/* .text-underline:hover:before {
  height: 100%;
  z-index: -1;
}
 */
.header_sub_text {
  /* background: -webkit-linear-gradient(#282D37, #797C7D);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  margin-top: -6vw;
  font-family: "Adys Regular", sans-serif;
  font-weight: 400;
  font-size: 2.6vw;
  color: #fff;
  /* text-shadow: 1px 1px 1px #282D37; */
}

.header_main_text {
  /* background: -webkit-linear-gradient(#282D37, #797C7D);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  font-size: 3vw;
  height: 11vw;
  width: 100%;
  font-family: "Adys Regular", sans-serif;
  font-weight: 400;
  color: #fff;
  /* text-shadow: 1px 1px 1px #282D37;  */
}

.learn-more-cont {
  margin-top: 9vw;
  /* margin-left: -6vw; */
}

.arrow-indicators-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.learn-more-btn {
  font-family: "Helvetica", sans-serif;
  font-weight: 400;
  font-size: 1.5vw;
  text-decoration: none;
  background-color: #fff;
   color: rgba(40, 180, 133, 0.7);
  /* color: #282D37; */
  border-radius: 5px;
  padding: 0.8vw;
  margin-top: 6vw;
  /* text-shadow: 1px 1px 1px grey;  */

}

.arrow-icon {
  margin-top: 2rem;
}

.heartbeat {
  animation-name: heartbeat;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.moveDown {
  animation-name: moveDown;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

/* ANIMATIONS */
@keyframes glitchAnim1 {
  0%,25.1%,100%{
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  }
  4%{
    clip-path: polygon(0 0, 100% 0, 100% 10%, 0 10%);
  }
  5%{
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }
  9%{
    clip-path: polygon(0 20%, 100% 20%, 100% 30%, 0 30%);
  }
  10%{
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
  }
  14%{
    clip-path: polygon(0 40%, 100% 40%, 100% 50%, 0 50%);
  }
  15%{
    clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
  }
  19%{
    clip-path: polygon(0 60%, 100% 60%, 100% 70%, 0 70%);
  }
  20%{
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
  }
  24%{
    clip-path: polygon(0 80%, 100% 80%, 100% 90%, 0 90%);
  }
  25%{
    clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
  }
}

@keyframes glitchAnim2 {
  0%,25.1%,100%{
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  }
  4%{
    clip-path: polygon(0 80%, 100% 80%, 100% 90%, 0 90%);
  }
  5%{
    clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    background-color: transparent;
  }
  9%{
    clip-path: polygon(0 60%, 100% 60%, 100% 70%, 0 70%);
  }
  10%{
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
  }
  14%{
    clip-path: polygon(0 40%, 100% 40%, 100% 50%, 0 50%);
  }
  15%{
    clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
  }
  19%{
    clip-path: polygon(0 20%, 100% 20%, 100% 30%, 0 30%);
  }
  20%{
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
  }
  24%{
    clip-path: polygon(0 0, 100% 0, 100% 10%, 0 10%);
  }
  25%{
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }
}

@keyframes showFromBottom {
  0%{
    transform: translateY(40px);
    opacity: 0;
  }

  100%{
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(2.4);
  }

  100% {
    transform: scale(2.7);
  }
}

@keyframes moveDown {
  0% {
    transform: translateY(50px) scale(1.2);
  }

  50% {
    transform: translateY(60px) scale(1.2);
  }

  100% {
    transform: translateY(50px) scale(1.2);
  }
}

@media screen and (max-width: 430px) {
  body{
    overflow-x: hidden;
  }
  .header-nav {
    width: 100%;
    margin-left: 3vw;
    display: flex;
    flex-direction: row;
    align-items: start;
    padding-left: 3vw;
  }

  .arrow-indicators-container {
    margin-top: -3.5rem;
  }

  .header {
    /* background-image: linear-gradient(to right, rgba(126, 213, 111, 0.7), rgba(40, 180, 133, 0.7)), url(../../../assets/human-and-dog.jpg); */
    background-image: linear-gradient(to right, rgba(126, 213, 111, 0.7), rgba(40, 180, 133, 0.7));
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding-top: 5%;
  }

  .text-logo-cont {
    width: 83%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    gap: 7vw;
  }

  .header .cont-row {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4vw;
  }

  .header .cont-row .glitch_wrap {
    overflow:unset
  }

  .header__logo {
    width: 120%;
    height: 120%;
    color: #fff;
    transform: scale(2.5);

  }

  .glitch{
    width: 100%;
    height: 100%;
    transform: scale(1.35);
  }

  .glitch-text_wrap{
    overflow: hidden;
   
    margin-bottom: 7vw;
  
    width: 50%;
    height: 10vh;
    font-size: 2vw;
    margin-top: 1vw;
  }
  
  .glitch-text_wrap .glitch-text {
    font-size: 5vw !important;
  }

  .mobile-menu-cont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .header__text-box {
    position: unset;
    transform: translate(0, 0);
    /* margin-top: 20vw; */
    padding-top: 0;
  }

  .header__text-box .heading-primary {
    margin: 20vw 0;
  }

  .cont-row .glitch-text_wrap {
    text-align: center;
  }

  .cont-row .glitch-text_wrap p {
    font-size: 6vw;
  }

  .cont-row .header-main-text-cont p {
    font-size: 5.5vw;
  }

  .header_sub_text {
    margin-top: 0vw;
    font-size: 5vw;
  }

  .text-underline {
    font-size: 5.8vw;
    position: relative;
  }

  .learn-more-cont {
    margin-top: 24vw;
  }

  .learn-more-btn {
    font-size: 4.5vw;
    padding: 2.8vw;
  }

  .bg-video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.15;
    overflow: hidden;
  }
  .bg-video__content {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }

  @keyframes moveDown {
    0% {
      transform: translateY(0) scale(2.1);
    }
  
    50% {
      transform: translateY(10px) scale(2.1);
    }
  
    100% {
      transform: translateY(0) scale(2.1);
    }
  }
}