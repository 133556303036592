@font-face {
    font-family: 'Adys Regular'; /* Name your font family */
    src: url('../../fonts/ADYS-Regular V5-5.ttf'); /* Locate the .ttf file within your directory*/
}

@font-face {
    font-family: 'Adys Bold'; /* Name your font family */
    src: url('../../fonts/ADYS-Bold V5-5.ttf'); /* Locate the .ttf file within your directory*/
}

.landing-container{
    padding: 0;
}

.features-container {
    margin-top: 10vh;
    transition: all 0.5s;
    width: 100%;
}

.separator-container {
    margin-top: -28.4vh;
    width: 100%;
}

.separator-container-horizontal {
    margin-top: -20vh;
    margin-bottom: -11vh;

}

.stories_cont {
    margin-top: -9.2vh;
    padding-bottom: 5vw;
    height: 160vh;
}

@media screen and (max-width: 600px) {
    .stories_cont {
        margin: 5vw 0;
        height: 190rem !important;
      }
}
