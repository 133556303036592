.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 10;
}

.pet_modal{
    margin-top: 10rem;
    display: flex;
    justify-content: center;
   
}

.pet_card-containter {
    width: auto;
    max-width: 50%;
    height: auto;
    max-height: 85vh;
    margin-bottom: 1vw;
    border-radius: 25px;
    background-color: #fff;
    box-shadow: 0 1px 2px #0000004d, 0 1px 10px #00000038;

    padding: 0.6vw 0;
    transition: all 0.5s;
}

.pet-close-icon-container {
    width: fit-content;
}

.pet_card-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 0 1rem;
}

.pet_card-top_container {
    width: 100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.pet_card-dog_image {
    width: 30%;
    border-radius: 8px;
}


.pet_card-separator-line {
    display: block;
    width: 80%;

    height: 1px;
    background-image: linear-gradient(to left, rgba(126, 213, 111, 0.2), rgba(40, 180, 133, 0.7));
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}


.pet_card-dog_details_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: center;
    gap: 1rem;
}

.pet_card-dog_details_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    text-align: center;
    padding-left: 30%;
}

.pet_card-dog_details_header1 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    text-align: center;
}

.pet_card-dog_details_header .dog-name {
    font-size: 2.3rem;
    font-family: "Adys Bold", sans-serif;
    color: rgba(95, 209, 171);
    font-weight: 700;
    margin-right: 0.2rem;
}

.pet_card-dog_details_header .dog-breed {
    font-family: "Adys Regular", sans-serif;
    color: rgba(0,0,0,0.6);
    font-size: 1.6rem;
}

.pet_card-dog_details_container .main-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-top: 0;
}

.pet_card-dog_details_container .dog-sex,
.pet_card-dog_details_container .dog-address,
.pet_card-dog_details_container .dog-color,
.pet_card-dog_details_container .weight,
.pet_card-dog_details_container .age
 {
    font-family: "Adys Regular", sans-serif;
    font-size: 1.4rem;
}

.pet_card-dog_details_container .dog-color {
}

.color-indicator {
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    margin-left: 0.4rem;
    margin-top: -0.5rem;
}

.info-row-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.2rem;
}

.checbox-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
}

.result_card-buttons {
    
}

.pet_card-bottom_container {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5vw;
}

.favourites_icon {
    cursor: pointer;
    border-radius: 50%; 
    padding: 4px;
    transition: 0.3s all;
}

.favourites_icon:hover {
    background-color: rgba(0,0,0,0.05);
}

.pet-description {
    width: 100%;
    border-radius: 8px;
    background-color: #F7F7F7;
    padding: 1rem;
    margin: 1.5rem 0;
    font-family: "Adys Regular", sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
    text-align: center;
}

.profile-image-wrapper {
    position: relative;
    background-color: #fff;
    overflow: hidden;
    margin-left: 0.2vw;
}

.profile-edit-image {
   position: absolute;
   bottom: 0;
   left: 0;
   outline: none;
   color: transparent;
   width: 100%;
   height: 3.5vw;
   box-sizing: border-box;
   background-color: rgba(0,0,0,0.3);
   border-bottom-left-radius: 8px;
   border-bottom-right-radius: 8px;

   cursor: pointer;
}

.profile-edit-image::-webkit-file-upload-button {
    visibility: hidden;
}

.profile-uploader{
    transition: all 0.5s;
    text-align: center;
    opacity: 0;
}

.profile-uploader:hover{
    transition: all 0.5s;
    opacity: 1;
}

.profile-uploader p{
    color: #fff;
    font-family: "Adys Regular", sans-serif;
    font-weight: 400;
    font-size: 1.8rem;
    margin: 0 auto;
}

.owner-details-container{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.owner-details-container p{
    font-size: 1.5rem;
    font-family: "Adys Regular", sans-serif;
    font-weight: 500;
}

.owner-details {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 1rem;
    margin-left: 0%;
    margin-top: 0.5rem;
}

.owner-details p {
    font-family: "Adys Bold",sans-serif;
    font-size: 1.7rem;
    font-weight: 500;
    color: rgba(95, 209, 171);
    margin-right: 4rem
}

.owner-details img{
    width: 4.4rem;
    height: 4.4rem;
    border-radius: 50%;
    border: 2px solid rgba(95, 209, 171);
    cursor: pointer;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
   
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
  
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
  
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
  
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
  
  }




