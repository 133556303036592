.separator-line {
    display: block;
    width: 80%;
    margin: 3rem auto;
    height: 1px;
    background-image: linear-gradient(to left, rgba(126, 213, 111, 0.2), rgba(40, 180, 133, 0.7));
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.result_cards {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 2rem;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;
    padding: 1rem;
    margin-bottom: 4rem;
    transition: 0.5s all;
}

.no-results-cont {
    text-align: center;
}

.no-results-message {
    font-family: "Ubuntu";
    font-size: 2.2rem;
    font-weight: 400;
    color: rgba(0,0,0,0.5);
}

@media screen and (max-width: 430px) {
    .result_cards {
        flex-direction: column;
        justify-content: start;
        align-items: center;
        gap: 2rem;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 4rem;
    }
}
