.confirm-btns-container {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.confirm-btns-container .confirm-btn {
   font-family: "Adys Regular";
   font-size: 2.3rem;
   padding: 0.4rem 0.7rem;
   cursor: pointer;
   color: rgba(0,0,0,0.6);
   border: 2px solid rgba(0,0,0,0.8);
}

.deny {
    transition: all 0.3s;
    background-color: #FF6666;
}

.deny:hover {
    transition: all 0.3s;
    transform: scale(1.02);
    color: rgba(0,0,0,1);
    background-color: #FF4C4C;
}

.confirm {
    transition: all 0.3s;
    background-color: rgba(40, 180, 133, 0.7);
}

.confirm:hover {
    transition: all 0.3s;
    transform: scale(1.02);
    color: rgba(0,0,0,1);
    background-color: rgba(40, 180, 133, 1);
}