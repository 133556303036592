html {
    overflow-x: hidden;
}

body {
    overflow-x: visible;
}

html::-webkit-scrollbar {
    width: 0.6vw;
    padding-left: 0;
    border-radius: 5px;
}

html::-webkit-scrollbar-thumb {
    background-color: rgba(40, 180, 133, 0.7);
    border-radius: 5px;
}

html::-webkit-scrollbar-track {
    background-color: #F6F6F6;
}

.user-profile-cont {
    /* background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: fit-content;
    width: 100vw;
    height: 100vh;
    font-weight: 300; */
}
.main-light {
    /* background: linear-gradient(to left, #55c57a, transparent) !important;  */
    background-color: #F4F4F4 ;
    margin-bottom: 8rem;
/*     background-image: linear-gradient(to left, rgba(178, 253, 165, 0.2), rgba(95, 209, 171, 0.2));
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; */
}

.main-dark {
    /* background-color: rgba(0,0,0,0.8); */
    margin-bottom: 8rem;
}

.content {
    width: 50vw;
    height: 100%;

    margin: 0 auto;
    display: flex;
    flex-direction: column;
    z-index: 0;
}

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .content {
        width: 90%;
    }

    .user-details .user-name{
        font-size: 1.9rem;
        width: max-content;
    }

    .user-details .user-address {
        font-size: 1.7rem;
    }

  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
  
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
  
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
  
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
  
  }