@font-face {
    font-family: 'Adys Regular'; /* Name your font family */
    src: url('../../fonts/ADYS-Regular V5-5.ttf'); /* Locate the .ttf file within your directory*/
}

@font-face {
    font-family: 'Adys Bold'; /* Name your font family */
    src: url('../../fonts/ADYS-Bold V5-5.ttf'); /* Locate the .ttf file within your directory*/
}

.pet-info-section-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    
}
  
.form-card {
    width: 50%;
    max-width: 72vw;
    padding: 2rem;
    border-radius: 0.8rem;
}

.background_dark {
    background-color: #333333;
    border: 0.1rem solid #eaeaea;
    box-shadow: 0 0.2rem 0.4rem rgba(255, 255,255, 0.5);
}

.background_light {
    background-color: #fff;
    border: 0.1rem solid #333333;
    box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.1);
}

.form-card-title {
    text-align: center;
    margin: 1vw 0;
}

.form-card-title p {
    font-family: "Adys Bold", sans-serif;
    font-size: 1.5vw;
}

.form-card form {
    margin-top: 8rem;
}

.profile-type-icon {
    font-size: 6rem;
    color: #333333;
}
  
.arrows-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1vw;
  }
  
.arrow {
    font-size: '14px';
}

.arrow_text {
    font-family: "Adys Bold", sans-serif;
    font-size: 1.3vw;
}

.buttons-cont {
    display: flex; 
    flex-direction: row;
    justify-content: end;
    width: 100%;
}
  
.weight-height-cont {
    display: flex;
    flex-direction: row;
    gap: 1vw;
}
  
.animated-dog-cont {
    margin-bottom: -5vw;
    z-index: 5;
}
  
.form-card__header {
    text-align: center;
    letter-spacing: 3px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: #65C466;
    font-size: 2rem;
}
  
.city-region-cont {
    display: flex;
    flex-direction: row;
    gap: 1vw;
    margin-top: -2vw;
}
  
.breed-color_cont {
    display: flex; 
    flex-direction: row;
    gap: 1vw;
}
  
.vaccinated {
    letter-spacing: 1px;
}
  
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
  
.dog-main-info_cont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2.5vw;
}
  
.name-breed_container {
    width: 100%;
    display: flex;
    flex-direction: column;
}
  
.upload_cont {
    border: 3px solid #7a7a83;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 13vw;
    height: 12vw;
    cursor: pointer;
    margin-right: -2vw;
    margin-left: -2vw;
}
  
.upload_cont:hover {
    border-color: #65C466;
}
  
.dog-details_cont {
    display: flex;
    flex-direction: row;
    gap: 1vw;
}
  
.date-cont {
    display: flex;
    flex-direction: row;
    justify-content: end;
}
  
.sex-age_cont {
    width: 82%;
}

.checkboxes-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
  
.pet-breed-checkboxes_cont {
    display: flex;
    flex-direction: column;
    margin-top: 1.4vw;
    gap: 2vw;
}

.pet-checkboxes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
  
.pet-originalBreed_cont {
    width: 100%;
    height: 15vw;
    border-radius: 25px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1vw 0;
    gap: 1vw;
}

.pet-originalBreed_cont p {
    font-family: "Adys Regular", sans-serif;
    font-size: 1.2vw;
}
  
.upload-breed_cont {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12vw;
    height: 12vw;
    cursor: pointer;
    
    border-radius: 50%;
}
  
.pet-info-section-container .MuiInputBase-formControl  svg {
    font-size: 2.5rem;
}
.card-btns-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.card-btns-container .card-btn-icon {
    transition: all 0.3s;
    color: rgba(0,0,0,0.8);
}

.card-btns-container .card-btn {
    transition: all 0.3s;
    color: rgba(0,0,0,0.8);
}

.card-btn:hover {
    transition: all 0.3s;
    color: rgba(255, 255, 255, 0.9);
}

.card-btn:hover .card-btn-icon {
    transition: all 0.3s;
    color: rgba(255, 255, 255, 0.9);
}

.card-btn:disabled .card-btn-icon {
    color: rgba(0,0,0,0.3);
}

.close-profile-card {
    position: absolute;
    right: 25%;
    top: 0%;
}

.gallery_container {
   /*  background-color: #F8F8F8;
    border: 2px solid #D9D9D9 !important; */

    display: flex;
    flex-direction: column;
    padding: 1rem;
  
    border-radius: 8px;
    width: 100%;
    margin: 0 auto;
    margin-top: -2vw;
}

.gallery_container .gallery-title{
    font-family: "Adys Regular", sans-serif;
    color: rgba(0,0,0,0.6);
    text-align: center;
    font-size: 1.3vw;
    margin-bottom: 1vw;
    margin-top: -1vw;
}

.gallery_container .images_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    margin: 1vw auto;
    width: 100%;
}

.profile-img-title-cont {
    width: 82%;
    display: flex;
    justify-content: start; 
    margin: 0 auto;
    margin-bottom: -1vw;
    margin-top: -0.5vw;
}

.profile-img-title-cont .profile-img-title {
    font-family: "Adys Bold", sans-serif;
    font-size: 1.2vw;
    color: rgba(40, 180, 133, 1);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .card_containter {
        width: 100%;
        margin-bottom: 1.5rem;
    }

    .dog-name {
        font-size: 1.4rem;
    }

    .dog-breed {
        font-size: 1.3rem;
    }

    .edit-btn {
        border-radius: 50%;
        padding: 0.4rem;
        margin-left: 1vw;
        margin-bottom: 0.2rem;
    }

    .profile-data-row .profile-data-icon {
        font-size: 1.3rem;
        margin-bottom: 0.4vw;
    }

    .profile-data-row p {
        font-size: 1.1rem;
    }

    .color-indicator {
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        margin-left: 0.05rem;
        margin-right: 0.4rem
    }

    .right-container {
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: end;
    }

    .left-container {
        width: 70%;
    }

    .right-container {
        align-items: start;
    }

    .checbox-container p {
        font-size: 1rem;
    }

    .profile-data-row {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.2rem;
        margin-bottom: 0.2rem;
    }
 
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}