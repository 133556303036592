.image-wrapper {
    position: relative;
    background-color: #fff;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 2vw;
}

.edit-image {
   position: absolute;
   bottom: 0;
   left: 0;
   outline: none;
   color: transparent;
   width: 100%;
   height: 3.5vw;
   box-sizing: border-box;
   background-color: rgba(0,0,0,0.5);
   cursor: pointer;
   transition: 0.5s;
}

.edit-image::-webkit-file-upload-button {
    visibility: hidden;
}

.uploader{
    opacity: 0;
}

.uploader:hover{
    opacity: 1;
}

.uploader p{
    color: #fff;
    font-weight: 900;
    font-size: 0.8vw;
}