@import "../variables";

.btn {

    text-transform: uppercase;
    border-radius: 10rem;
    padding: 1.5rem 4rem;
    border: none;
    display: inline-block;
    transition: all .2s;
    letter-spacing: 3px;
    font-weight: 400;
    position: relative;
    cursor: pointer;
    backface-visibility: hidden;

    &:hover {
        transform: translateY(-3px);
        box-shadow: 0 1rem 2rem rgba(0, 0, 0, .2);
    }


    &:active {
        transform: translateY(-1px);
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .2);
    }


    &::after {
        content: "";
        display: inline-block;
        height: 100%;
        width: 100%;
        border-radius: 10rem;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transition: all .4s;
    }


    &:hover::after {

        transform: scaleX(1.4) scaleY(1.6);
        opacity: 0;
    }


    &-white {
        background-color: $color-white;
        color: $color-grey-dark;
    }



    &-white::after {
        background-color: $color-white;
    }




    &-animated {

        animation: moveInBottom .5s ease-out .75s;
        animation-fill-mode: backwards;

    }

}


.btn-text {

    &:link,
    &:visited {
        font-size: $default-font-size;
        color: $color-primary;
        display: inline-block;
        text-decoration: none;
        border-bottom: 1px solid $color-primary;
        padding: 3px;
        transition:all .2s;
    }

    &:hover {
        background-color: $color-primary ;
        color: $color-white;
        box-shadow: 0 1rem 2rem rgba($color-black, .15);
        transform: translateY(-2px);
    }

    &:active {

        box-shadow: 0 0.5rem 1rem rgba($color-black, .15);
        transform: translateY(0);
    }
}