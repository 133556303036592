@font-face {
    font-family: 'Adys Regular'; /* Name your font family */
    src: url('../../../../fonts/ADYS-Regular V5-5.ttf'); /* Locate the .ttf file within your directory*/
}

@font-face {
    font-family: 'Adys Bold'; /* Name your font family */
    src: url('../../../../fonts/ADYS-Bold V5-5.ttf'); /* Locate the .ttf file within your directory*/
}

.edit-form-container {
    width: 40%;
    padding: 2rem;

    margin: 0 auto;
    margin-top: 4vw;
    background-color: #fff;
    border-radius: 8px;
    
}

.close-edit-profile-card {
    position: absolute;
    right: 30%;
    top: 0%;
}

.edit-form-title {
    text-align: center; 
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1vw;
    margin-bottom: 3rem;
}

.edit-form-title p {
    font-size: 1.8vw;
}

.edit-buttons-container {
    margin: 1.5rem 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 1vw;
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear
{
    display: none;
}

.errorContainer {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    margin-top: 0.8rem;
    margin-left: 1rem;

}

.errorContainer .errorMessage {
    color: #A51414 !important;
    font-family: 'Adys Regular', sans-serif;
    font-size: 1vw;
    margin: 0;
    margin-left: 0.5rem;
}


.opened {
    color: rgb(126, 213, 111);
}

.closed {
    color: grey;
}

.edit-form-container .MuiAutocomplete-endAdornment .MuiAutocomplete-clearIndicator {
    transform: scale(1);
}

.edit-form-container .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    min-width: 3.8rem !important;
}

.edit-form-container .MuiAutocomplete-endAdornment svg {
    font-size: 2.5rem;
}

.edit-buttons-container .edit-card-btn {
    transition: all 0.3s;
    color: rgba(0,0,0,0.8);
}

.edit-card-btn .edit-card-btn-icon {
    transition: all 0.3s;
    color: rgba(0,0,0,0.8);
}

.edit-card-btn:hover {
    transition: all 0.3s;
    color: rgba(255, 255, 255, 0.9);
}

.edit-card-btn:hover .edit-card-btn-icon {
    transition: all 0.3s;
    color: rgba(255, 255, 255, 0.9);
}

.edit-card-btn:disabled .edit-card-btn-icon {
    color: rgba(0,0,0,0.3);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .edit-form-container {
        width: 90%;
        padding: 2rem;
        margin: 0 auto;
        margin-top: 4vw;
        background-color: #fff;
        border-radius: 8px;
    }

    .edit-form-title p {
        font-size: 1.5rem;
    }

    .close-edit-profile-card {
        position: absolute;
        right: 5%;
        top: 0%;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}
