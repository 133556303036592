.drag-action-cont {
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10;

    position:"absolute";
    bottom: 1vw;
    left: 4.5vw;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.drag-action-cont:hover {
    opacity: 1;
}

.image-cont,
.profile-image-cont {
    height: 12vw;
    width: 10vw;
    border: 2px solid #D9D9D9;
    
    border-radius: 5px;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 0;
    cursor: pointer;
}

.profile-image-cont {
    border: 3px solid rgba(40, 180, 133, 0.7);
}

.image-cont:hover{
    border: 2px solid #9A9A9A;
}

.profile-image-cont:hover{
    border: 3px solid rgba(40, 180, 133, 1);
}

.image-cont .image {
    box-shadow: 0 0 5px inset rgba(0,0,0,0.5);
}

.profile-image-cont .image {
    box-shadow: 0 0 5px inset rgba(40, 180, 133, 0.7);
}