.main-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10vw;
    margin-bottom: 0;
    height: max-content;
}

.heading_secondary {
    font-size: 3.5rem;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    background-image: linear-gradient(to right, #7ed56f, #28b485);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    letter-spacing: 2px;
    transition: all 0.2s;
}

.subscribed-message-cont.error-message {
    background-color: #ffe5e5; 
}

.subscribed-message-cont.success-message {
    background-color: #E7F1E7; 
}

.form-inputs-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form-inputs-container .form-inputs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50vw;
    gap: 1vw;
}

.form-inputs .form-input-field{
   width: 49%;
}

.form-checks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 1vw;
    margin-top: 2vw;
}

.checkbox-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1vw;
  
    width: 81%;
}

.checkbox-container .checkbox {
    color: rgba(40, 180, 133, 0.7) !important;
    transform: scale(1.5);
}

.checkbox-container .checkbox:checked {
    color: rgba(40, 180, 133, 0.7);
}

.checkbox-label {
    font-size: 1vw;
    font-family: "Adys Regular", sans-serif;
    font-weight: 400;
}

.gdpr-link {
    cursor: pointer;
    color: rgba(40, 180, 133, 1);
    text-decoration: underline;
}

.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3vw;
    margin-top: 2vw;
    margin-left: 4vw;
    transition: all 0.5s;
    height: max-content;
    width: 70%;
}

.text-container {
    text-align: center;
}

.form-header-text {
    text-align: center;
    margin-top: 3vw;
}

.form-header-text p {
    font-size: 1.3vw;
    font-family: "Adys Regular", sans-serif;  
}

.form-header-text .form-header-img {
    font-size: 3vw; 
    color: rgba(40, 180, 133, 0.7);
}

.form-container .form-button {
    width: 76%; 
    padding: 1rem 0.5rem;
    font-size: 1.5rem;
    font-family: "Adys Bold", sans-serif;
    font-weight: 600;
    margin-top: -2vw;
    background-color: #65C466; 
    margin: 2vw 7vw;
}

.form-container .form-button:hover {
    background-color: #509C51; 
    transform: scale(1.03);
}

.mandatory {
    margin-left: 0.5vw;
    font-size: 1.4rem;
}

.message-cont {
    margin-top: -1.8vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5vw;
}

.message-text {
    font-size: 1.1vw;
    font-family: "Adys Regular", sans-serif;
    font-weight: 400;   
}

.message-icon {
    font-size: 3rem;
    margin-bottom: 0.5rem;
    display: inline-block;
    fill:rgba(40, 180, 133, 0.7);
    margin-top: 0.4vw;
}

.subscribed-message-cont {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.subscribed-cont .subscribed-icon {
    font-size: 10vw; 
    color: rgba(126, 213, 111, 0.7);
    margin-bottom: 2vw;
}

.subscribed-cont .error-icon {
    font-size: 7vw; 
    color: #900B1A;
}

.subscribed-message-cont p{
    font-family: "Adys Regular", sans-serif;
    font-size: 2vw;
    text-align: center;
}

.subscribed-message-cont .error_txt {
    background-image: linear-gradient(to right, #ce1126, #900B1A);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.subscribed-message-cont .success_txt {
    background-image: linear-gradient(to right, #7ed56f, #28b485);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.phone-container {
    margin-left: 0vw;
    height: max-content;
}

.phone {
    background-image: url('../../../assets/phone.png');
    background-position: center;
    background-size: cover;
    width: 32vw;
    height: 42vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;
    /* animation: moveInRight 1s ease-out; */
}

.hide-left{
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-100%);
    transition: all 0.8;
}

.hide-right{
    opacity: 0;
    filter: blur(5px);
    transform: translateX(100%);
    transition: all 0.8;
}

.show {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
}

.mobile_header_txt {
    background: linear-gradient(to right, rgba(126, 213, 111, 0.7), rgba(40, 180, 133, 0.7));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Adys Bold", sans-serif;
    font-size: 1.8vw;
}

.mobile-sub-txt {
    font-family: "Adys Regular", sans-serif;
    font-weight: 300;
    font-size: 1.3vw;
}

.heading-sub-text {
    font-family: "Adys Regular", sans-serif;
    font-weight: 400;
    font-size: 1.5vw;
}

.glitch_mobile_wrap {
overflow: hidden;
position: relative;
width: 9.4vw;
height: 9.4vw;
transform: rotateZ(-8deg);

margin-bottom: 0;
transition: all 0.2s;
/* animation: moveInBottom 1s ease-out; */
}

.glitch_mobile{
position: absolute;
left: 0;
top: 0;
right: 0;
bottom: 0;
background: url('../../../assets/paw-glitch-bright.png') no-repeat center center;
background-size: cover;
transform: scale(0.45);
}

.glitch_mobile:before,
.glitch_mobile:after {
content: "";
position: absolute;
left: 0;
top: 0;
right: 0;
bottom: 0;
background: inherit;
transform: translateX(2px);
animation-duration: 4s;
animation-timing-function: linear;
animation-iteration-count: infinite;
animation-fill-mode: forwards;

background-blend-mode: difference;
}

.glitch_mobile:before {
animation-name: glitchAnim1;
}

.glitch_mobile:after {
animation-name: glitchAnim2;
}

.phone-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4vw;
    margin-top: -4vw;
    margin-left: -5.5vw;
}

.phone-txt {
    transform: rotateZ(-6deg);
    font-size: 1.3vw;
    margin-top: -1.4vw;
    margin-left: -1vw;
    font-family: "Adys Regular", sans-serif;
    font-weight: 600;
}

.header_logo {
    width: 100%;
    height: 100%;
    color: #fff;
}


.features {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 0.6vw;
}

.feature {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    transform: rotateZ(-9deg);
    width: 13vw;
    gap: 1vw;
    margin-left: vw;
    border: solid 1px #000;
    border-radius: 5px;
    padding: 1rem;
    cursor: pointer;
    transition: 0.5s;
}

.feature:hover {
    transform: rotateZ(-9deg) scale(1.05);
    border: 1px solid rgba(40, 180, 133, 0.7);
    transition: 0.5s;
}

.feature-txt {
    font-size: 1vw;
    font-family: "Adys Regular", sans-serif;
    font-weight: 400; 
    background: linear-gradient(to right, rgba(126, 213, 111, 0.7), rgba(40, 180, 133, 0.7));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;   
}

.subscribed-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* transform: scale(0); */
}

.show-subscribe {
    transition: all 0.5s;
    transform: scale(1);
}

/* ANIMATION CLASSES */

.show {
    animation: show 0.6s linear ease-in-out;
    animation-fill-mode: forwards;
}

/* ANIMATIONS */
@keyframes show {
    0% {
        opacity: 0;
        transform: scaleZ(0);
    }
    100% {
        opacity: 1;
        transform: scaleZ(1);
    }
}

@media screen and (max-width: 430px) {
    .section-subscribe {
        margin-top: 34vw;
    }

    .section-subscribe .heading_secondary {
        font-size: 5vw;
    }

    .form-inputs-container .form-inputs {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 1vw;
        margin: 2vw 0;
    }

    .form-inputs .form-input-field {
        width: 100%;
    }

    .section-subscribe .u-margin-bottom-big {
        margin-bottom: 4rem;
    }
    
    .form-container {
        width: 90%;
        margin: 0 auto !important;
    }

    .form-checks .checkbox-container {
        width: 100%;
        margin-bottom: 1vw;
    }

    .checkbox-container .checkbox {
        transform: scale(1.8);
    }

    .form-checks .checkbox-container .checkbox-label {
        font-size: 2.5vw;
    }

    .mandatory {
        margin-left: 0.5vw;
        font-size: 1.2rem;
    }

    .heading-secondary {
       font-size: 6.2vw;
       width: 80%;  
    }

    .heading-sub-text {
        font-size: 4vw;
    }

    .mobile_header_txt {
        font-size: 6.2vw;
    }

    .mobile-sub-txt {
        font-size: 3.8vw;
    }

    .form-header-text {
        margin-top: 3rem;
    }

    .form-header-text p {
        text-align: center;
        width: 100%;
        font-size: 3vw;
    }

    .form-header-text .form-header-img {
        font-size: 6vw; 
    }

    .form-container .form-button {
        width: 98%; 
        padding: 1rem 0.5rem;
        font-size: 1.5rem;
        margin-top: 2rem;
        margin-bottom: 1rem;
        margin-left: 0;
    }
    
    .form-container .form-button:hover {
        transform: scale(1.03);
    }

    .message-cont {
        margin-top: -1.8vw;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 0.5vw;
    }
    
    .message-text {
        font-size: 3vw;
        font-family: "Adys Regular", sans-serif;
        font-weight: 400;   
    }
    
    .message-icon {
        font-size: 2.4rem;
        margin-bottom: 0.2rem;
        display: inline-block;
        fill:rgba(40, 180, 133, 0.7);
        margin-top: 0.4vw;
    }

    .phone-container {
        display: none;
    }

    .main-container {
        margin: 0 auto !important;
    }

    .subscribed-message-cont {
        width: 100%;
        padding: 2rem;
    }
    
    .subscribed-cont .subscribed-icon {
        font-size: 3rem; 
        margin-bottom: 2vw;
    }
    
    .subscribed-cont .error-icon {
        font-size: 4rem; 
    }

    .subscribed-message-cont p{
        font-family: "Ubuntu";
        font-size: 1.4rem;
        text-align: center;
    }

    .MuiCheckbox-root .MuiSvgIcon-root {
        font-size: 2rem;
    }

}


