.accordion_cont {
    margin-top: -10.7vw;
}

.pet-icon {
    position: relative;
    width: 11vw;
    left: 74.5vw;
    top: 10vw; 
    transition: all 0.1s;
    transform: rotateZ(90deg);
    opacity: 0;
}

.expand-icon {
    font-size: 2.5vw !important;
}

.accordion-summary div{
    font-size: 1.5vw;
    font-family: "Adys Regular", sans-serif;
    font-weight: 500; 
    color: rgba(0,0,0,0.8);
}

/* 
.pet-icon:hover {
    opacity: 1;
    left: 76.5vw;
} */

.show-pet {
    opacity: 1;
    left: 76.5vw;
    transition: all 0.5s;
}

.show-pet-small {
    opacity: 1;
    left: 75.6vw;
    transition: all 0.5s;
}


.accordion {
    border: 3px solid #838181;
    border-top: 1px; 
    border-bottom: 1px;
    border-right: 0;
    margin-top: 0.1vw;
    transition: all 0.5s;
    transform-origin: top;
}

.accordion:hover {
    border-color:  #28b485;
}

.faq-text {
    margin: 0 auto;
    transition: all 0.5s;
    transform-origin: top;
}

.faq-text p{
    font-size: 1.3vw;
    font-family: "Adys Regular", sans-serif !important;
    background-image: linear-gradient(to right, #7ed56f, #28b485);
    background-clip: text;
    color: transparent;
}



.faq-text .social-list {
    display: flex;
    gap: 2rem;
    margin-top: 1vw;
    width: max-content;
    border-top: 1px solid #000;
    padding-top: 0.5vw
}
  
.social-list li span {
    cursor: pointer;
}

.faq-text .MuiSvgIcon-root.social-icon {
    color: rgba(126, 213, 111, 0.7);
    width: 3rem !important;
    height: 3rem !important;
    filter: drop-shadow(1px 1px 1px #000);
}
  
.faq-text .MuiSvgIcon-root:hover.social-icon {
    transform: scale(1.05);
}

@media screen and (max-width: 430px) {
    .faq-text p {
        font-size: 3.2vw;
    }

    .expand-icon {
        font-size: 6vw !important;
    }

    .accordion-summary div{
        font-size: 3.5vw;
    }

    .pet-icon {
        width: 16vw;
        position: relative;
        left: 75vw;
        top: 15vw; 
    }

    .accordion {
        border-right: 0;
        margin-top: -4vw;
    }

    .faq-text .MuiSvgIcon-root.social-icon {
        width: 2rem !important;
        height: 2rem !important;
    }
    
}
