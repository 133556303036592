ul[role=list],
ol[role=list] {
  list-style: none;
}

.primary-footer-nav .footer-nav ul.flow[role=list] > li > a {
  font-size: 1.8rem;
}

/* MUI Icon adjustments to theme */
.MuiSvgIcon-root.social-icon {
  color: white;
  width: 3rem !important;
  height: 3rem !important;
  filter: drop-shadow(1px 1px 1px #000);
  z-index: 300;
}

.MuiSvgIcon-root:hover.social-icon {
  color: #2A623C;
  transform: scale(1.05);
}

.socials {
  margin-left: 3vw;
  display: flex; 
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  margin-top: 2.4vw;

}

.socials p {
  font-family: "Adys Bold", sans-serif;
  color: #fff; 
  font-size: 1.2vw;
  border-bottom: 1.5px solid #fff;
  box-shadow: 0px 1px 0px #000;
  z-index: 300;

}

/*social list */
.social-list {
  display: flex;
  gap: 2rem;
  padding-top: 0.5vw;
}

.social-list li span {
  cursor: pointer;
  z-index: 300;
}

.primary-footer {
  /* background-color: #333; */
  background: linear-gradient(to left, rgba(178, 253, 165, 1), rgba(95, 209, 171, 1));
  z-index: 300;
  padding: 1rem 1rem;
}

.footer-logo-cont {
  display: flex; 
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1.4vw;
  gap: 1vw;
}

.footer-logo {
  display: block;
  height: 7rem;
  width: 8rem;
  filter: drop-shadow(1px 1px 1px #000);

}

.footer-nav a {
  font-family: "Adys Bold", sans-serif;
  color: white;
  text-decoration: none;
  width: fit-content;  
  z-index: 300;

}

.footer-nav a:is(:hover) {
  color: #2A623C;
}

.footer-nav {
  display: flex;
  align-items: center;
  margin-right: 1vw;
  border-left: 1px solid #fff;
  padding-left: 2vw;
}

.footer-nav ul{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2vw;
  width: 50vw;
}


.footer-nav li {
  width: max-content;
  font-size: 1.4vw;
}

.primary-footer-wrapper {
  display: flex;
  gap: 4vw;
  justify-content: space-between;
  z-index: 300;
}

.primary-footer-logo-social {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 2vw;
}

.primary-footer-logo-social a {
  transition: transform 0.5s;
}

.primary-footer-logo-social a:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 430px) {
  .primary-footer-logo-social {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 2vw;
    margin-left: 2vw;
  }

  .footer-logo-cont {
    flex-direction: row;
  }

  .footer-logo {
      height: 4.5rem;
      width: 5rem;
      margin-right: 1vw;
  }

  .footer-logo-cont p {
    font-size: 2.5vw;
  }

  .socials {
    margin-left: 1vw;
    display: flex; 
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    margin-top: 2.4vw;
    margin-left: -5vw;
  }

  

  .socials p {
    color: #fff; 
    font-size: 4vw;
    margin-bottom: 0.4vw;
    border-bottom: 1px solid #fff;
    box-shadow: none;
  }

  .footer-nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin: 0;
    padding: 0;
    width: 45vw;
  }
  
  .footer-nav ul{
    display: flex;
    flex-direction: column;
    align-items: center;
    
    margin: 0;
    padding: 0;
  }

  .footer-nav ul li{
    font-size: 3.5vw;
    width: 80%;
  }

}