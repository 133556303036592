.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 10;
}

.module-popup-container{
    width: 90%;
    padding: 0.5rem;
    padding-top: 1rem;

    height: fit-content;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,0.7);
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.7);
    margin: 0 auto;
}

.close-icon-container {
    width: 100%;
    text-align: end;
}

.module-popup-content {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.module-popup-content h4 {
    font-family: "Adys Regular";
    font-weight: 500;
    font-size: 2.1rem;
}

.module-popup-content div {
    text-align: center;
}

.module-popup-content div p {
    font-family: "Adys Regular";
    font-weight: 400;
    font-size: 1.5rem;
    margin-top: 0.2rem;
}

.module-name {
    font-family: "Adys Bold";
    font-weight: 500;
    font-size: 2rem;
    color:  rgba(40, 180, 133, 0.7);
    /* text-shadow: 1px 1px 1px #000; */
}

.image-title-container {
    display: flex; 
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-top: -4rem;
    margin-bottom: 2rem;
    width: fit-content;
}

.module-summary-popup-image-container {
    width: 6rem;
    height: 6rem;
    display: flex
;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 5px inset rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(40, 180, 133, 0.7);
    box-shadow: 0 0 5px inset rgba(40, 180, 133, 0.7);
    border-radius: 50%;
}

.image-title-container .big {
    margin-left: 1rem;
    font-size: 2rem;
    font-family: "Adys Bold", sans-serif;
    color:  rgba(40, 180, 133, 1);

}

.open-module-btn-container {
    margin-top: 4rem;
    width: 100%;
    display: flex;
    justify-content: end;
}

.open-module-btn-container .open-module-btn {
    font-family: "Adys Bold", sans-serif;
    font-size: 1.6rem;
    padding: 0.4rem 0.7rem;
    cursor: pointer;
    color: rgba(0,0,0,0.6);
    border: 2px solid rgba(0,0,0,0.8);
    border-radius: 8px;
    transition: all 0.3s;
    background-color: rgba(40, 180, 133, 0.7);
}

.open-module-btn-container .open-module-btn:hover {
    transition: all 0.3s;
    transform: scale(1.02);
    color: rgba(0,0,0,1);
    background-color: rgba(40, 180, 133, 1);
}

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .info-popup-container {
        transform: scale(0.8);
    }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
  
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
  
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
  
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
  
  }