@import "./variables";

body {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-size: $default-font-size;
    line-height: 1.7;
    color: gray;
    margin: 2rem;
}


.heading-primary {

    color: $color-white;
    text-transform: uppercase;
    text-align: center;
    backface-visibility: hidden;
    margin-bottom: 6rem;


    &--main {
        display: block;
        font-size: 6rem;
        font-weight: 700;
        letter-spacing: 1rem;

        animation: moveInLeft 1s ease-out;
    }

    &--sub {
        display: block;
        font-size: 2.2rem;
        font-weight: 700;
        letter-spacing: 1.5rem;

        animation: moveInRight 1s ease-out;
    }
}


.heading-secondary{
    font-size: 3.5rem;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    background-image: linear-gradient(to right,$color-primary-light, $color-primary-dark);
    background-clip: text;
    color: transparent;
    letter-spacing: 2px;
    transition: all .2s;

    &:hover{
        transform: skewY(2deg) skewX(13deg) scale(1.1);
        text-shadow: .5rem 1rem 2rem rgba($color-black,.2);
    }
}


.heading-tertiary{

    font-size: $default-font-size;
    font-weight: 700;
    text-transform: uppercase;
    transition: all .2s;
    display: inline-block;

    &-animated:hover{
        background: linear-gradient(to right, $color-primary-light 20%, $color-primary 30%, $color-primary-dark 80% );
        background-clip: text;
        -webkit-text-fill-color: transparent;
        transform: translateY(-4px);

    }

    text-align: center;

}


.paragraph{

    font-size: $default-font-size;
    
    &:not(:last-child){
        margin-bottom: 3rem;
    }
}