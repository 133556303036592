html {
    overflow-x: visible;
}

html::-webkit-scrollbar {
    width: 0.6vw;
    padding-left: 0;
    border-radius: 5px;
}

html::-webkit-scrollbar-thumb {
    background-color: rgba(40, 180, 133, 0.7) !important;
    border-radius: 5px;
}

html::-webkit-scrollbar-track {
    background-color: #F6F6F6;
}

.add-profile-cont-light {
    width: 50vw;
    height: 15vw;
    margin: 2vw auto;
    border-radius: 25px;
    background-color: #EDEDED;
    box-shadow: 0 0 5px inset rgba(0,0,0,0.5);

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.add-profile-cont-dark {
    width: 50vw;
    height: 15vw;
    margin: 2vw auto;
    border-radius: 25px;
    background-color: #191919;
    box-shadow: 0 0 5px inset rgba(255,255,255,0.5);

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.outter-cont {
    /* overflow-y: scroll; */
    overflow-x: visible;
    height: max-content;
    width: fit-content;
    padding: 0 1vw;
}

.outter-cont::-webkit-scrollbar {
    width: 0.6vw;
    padding-left: 0;
    border-radius: 5px;
}

.outter-cont::-webkit-scrollbar-thumb {
    background-color: rgba(40, 180, 133, 0.7) !important;
    border-radius: 5px;
}

.outter-cont::-webkit-scrollbar-track {
    background-color: #F6F6F6;
}

.inner-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.inner-cont p {
    font-family: "Adys Regular", sans-serif;
    font-size: 1.4vw;
    color: rgba(0,0,0,0.6);
}

.add-pet-profile-icon {
    font-size: 4.5vw;
    color: #7a7a83;
    cursor: pointer;
    margin-top: 1vw;
}

.add-pet-profile-cont:hover .add-pet-profile-icon {
    color: rgba(95, 209, 171, 0.6);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .outter-cont {
        width: 100%;
    }

    .add-profile-cont-dark,
    .add-profile-cont-light {
        width: 100%;
        height: 10rem;
    }

    .inner-cont {
        width: 90%;
        height: 98%;
    }

    .inner-cont p {
        font-size: 1.3rem;
    }

    .add-pet-profile-icon {
        font-size: 2.5rem;
   
        margin-top: 1vw;
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}