html {
    overflow-y: scroll;
}


.hump-welcome_message {
    font-family: "Helvetica";
    font-size: 2.8rem;
    font-weight: 400;
    color: #000;
    text-shadow: 1px 0px 1px #000;
    letter-spacing: 0.2rem;
}

.hump-welcome_message-name {
    font-family: "Helvetica";
    font-size: 3.1rem;
    font-weight: 600;
    color: rgba(95, 209, 171, 1);
    background: linear-gradient(to left, rgb(178, 253, 165), rgb(95, 209, 171));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* text-shadow: 1px 1px 1px #000; */
}

.hump-reveal {
    animation: hump-reveal 0.7s ease-in-out;
    animation-fill-mode: forwards;
}

.hump-showFromTop {
    animation: showFromTop 0.7s ease-in-out;
    animation-fill-mode: forwards;
}

.hump-showFromLeft {
    animation: hump-showFromLeft 0.7s ease-in-out;
    animation-fill-mode: forwards;
}

.hump-pagination-container {
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
}

.filters-icon {
    font-size: 3rem; 
    color: rgba(0,0,0,0.5);
    margin-top: -1rem;
    cursor: pointer;
    border-radius: 50%; 
    padding: 4px;
    transition: 0.3s all;
}

.filters-icon:hover {
    background-color: rgba(0,0,0,0.05);
}

.hidden {
    display: none;
}

.loader-cont {
    margin: 8rem 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#loader {
    position: block;
    width: 110px;
    height: 110px;
   
    animation: rotate 1s linear infinite;
}

svg circle {
    width: 100%;
    height: 100%;
    fill: none;
    stroke-width: 6;
    stroke-linecap: round;
    stroke: rgba(95, 209, 171);
    stroke-dasharray: 440;
    stroke-dashoffset: 440;
    transform: translate(5px,5px);

    animation: animate 4s linear infinite;
}


@keyframes glow {
    0% {
        filter: drop-shadow(0 0 0 rgb(95, 209, 171));
    }
    50% {
        filter: drop-shadow(0 0 3px rgb(95, 209, 171));
    }
    100% {
        filter: drop-shadow(0 0 0 rgb(95, 209, 171));
    }
}

@keyframes heart {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes animate {
    0%, 100% {
        stroke-dashoffset: 440;
    }
    50% {
        stroke-dashoffset: 0;
    }
    50.1% {
        stroke-dashoffset: 880;
    }
}

@keyframes showFromTop {
    0% {
      opacity: 0;
      transform: translateY(-30px)
    }
    100% {
      opacity: 1;
      transform: translateY(20px);
    }
}

@keyframes hide {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
}
  
  
@keyframes hump-reveal {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes hump-showFromLeft {
    0% {
        opacity: 0;
        transform: translateX(-500px)
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes showFromTop {
    0% {
        opacity: 0;
        transform: translateY(-100px)
    }
    100% {
        opacity: 1;
        transform: translateY(20px);
    }
}
