

:root {
  ----clr-primary-100: 263 55% 52%;
  --clr-primary-400: 263 55% 52%;
  --clr-secondary-400: 217 19% 35%;
  --clr-secondary-500: 219 29% 14%;
  --clr-neutral-100: 0 0% 100%;
  --clr-neutral-200: 210 46% 95%;
  --clr-neutral-300: 0 0% 81%;
  --fw-400: 500;
  --fw-700: 600;
  --fs-300: 0.6875rem;
  --fs-400: 0.8125rem;
  --fs-500: 1.25rem;
}

.container {
  padding: 0 2rem;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}

/* utilities */
.flex {
  display: flex;
  gap: var(--gap, 1rem);
}

.flow > *:where(:not(:first-child)) {
  margin-top: var(--flow-spacer, 1em);
}

.bg-primary-100 {
  background-image: linear-gradient(to right bottom, rgba(126, 213, 111, 0.7), rgba(40, 180, 133, 0.7));
  background-size: cover;
}

.bg-primary-300 {
  background: #222;
}

.bg-primary-400 {
  background: hsl(var(--clr-primary-400));
}

.bg-secondary-400 {
  background: hsl(var(--clr-secondary-400));
}

.bg-secondary-500 {
  background: hsl(var(--clr-secondary-500));
}

.bg-secondary-600 {
  background: linear-gradient(to right bottom, rgba(126, 213, 111, 0.7), rgba(40, 180, 133, 0.7));
}

.bg-neutral-100 {
  background: hsl(var(--clr-neutral-100));
}

.text-neutral-100 {
  color: hsl(var(--clr-neutral-100));
}

.text-secondary-400 {
  color: hsl(var(--clr-secondary-400));
}

.border-primary-500 {
  border: 2px solid #fff;
}

.border-primary-400 {
  border: 2px solid #a775f1;
}

.border-primary-600 {
  border: 2px solid rgba(40, 180, 133, 0.7);
}

.border-primary-300 {
  border: 2px solid hsl(var( --clr-secondary-400));
}

.wide {
  width: 70%;
}

.long {
  height: 70%;
}

/* components */
.testimonial-grid {
  /* display: grid; */
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  /* grid-auto-columns: 1fr 0.6fr; */
  /* grid-template-areas: "one" "two" "three" "four"; */
  padding-block: 2rem;
  width: 100%;
  margin-inline: auto;
  padding: 0 2rem;
}

.testimonial {
  font-family: "Adys Regular", sans-serif;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 2.5rem 3.75rem 3rem -3rem hsl(var(--clr-secondary-400)/0.25);
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  height: max-content;
}

article.testimonial:hover {
 transform: scale(1.02);
 cursor: pointer;
}

.testimonial__review-heading {
  font-family: "Adys Bold", sans-serif;
  font-size: 1.7rem;
  margin-bottom: 0.8rem;
}
.testimonial__review-text {
  font-family: "Adys Regular", sans-serif;
  font-size: 1.5rem;
}

.testimonial > .flex > div:nth-child(2) {
  align-content: center;
}

.testimonial > .flex > div:nth-child(2) h3 {
  font-size: 1.6rem;
}

.testimonial:nth-child(1) {
  grid-area: one;
  height: max-content;
}

.testimonial:nth-child(2) {
  grid-area: two;
}

.testimonial:nth-child(3) {
  grid-area: three;
  margin-top: -5vw;
}

.testimonial:nth-child(4) {
  grid-area: four;
}

.testimonial.quote {
  background-image: url("./images/bg-pattern-quotation.svg");
  /* background-image: url("../../../assets/paw-white.png"); */
 
  background-repeat: no-repeat;
  background-position: top right 1%;
}

.testimonial img {
  width: 6.5rem;
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 2px;
}

.testimonial .name {
  line-height: 1;
  font-family: "Adys Bold", sans-serif;
  font-size: 1.8rem !important;
}

.testimonial .job-title {
  line-height: 1;
  font-family: "Adys Regular", sans-serif;
  font-size: 1.5rem !important;
  font-weight: 600;
  margin-top: 0.5vw;
  color: #a775f1;
}

.testimonial .position {
  opacity: 0.5;
}

.testimonial > p:first-of-type {
  line-height: 1.2;
}

.testimonial > p:last-of-type {
  opacity: 0.7;
}

/* .heading-animated {
  opacity: 0;
  transform: translateX(35px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
} */

.animate-in {
  opacity: 1;
  transform: translateX(0);
}

.animated-grid-item.animate-in {
  opacity: 1;
  transform: translateY(0);
}

.animated-grid-item.item1 {
  animation: fadeInUp 1s ease-out;
}

.animated-grid-item.item2 {
  animation: fadeInRight 1s ease-out;
}

.animated-grid-item.item3 {
  animation: fadeInLeft 1s ease-out;
}

.animated-grid-item.item4 {
  animation: fadeInDown 1s ease-out;
}

.animated-grid-item.item5 {
  animation: fadeInRightDown 1s ease-out;
}

.stories-container {
  margin: 5vw 0;
  margin-top: 3rem;
  padding-top: 8rem;
  height: 120vh;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}

.stories-container .heading_secondary {
  font-family: "Adys Regular", sans-serif;
  font-size: 3vw;
  margin-bottom: 3vw;
  background-image: linear-gradient(to right, #7ed56f, #28b485);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

/* GRID LAYOUT */
.grid-container {
  display: grid;
  gap: 15px;
  row-gap: 10px;
  /* background-color: #2196F3; */
  padding: 10px 20px;
}

.grid-item {
/* background-color: rgba(255, 255, 255, 0.8);*/
  /* padding: 20px; */
  font-size: 30px;
}

.item1 {
  grid-column: 1 / span 2;
  grid-row: 1;
}

.item2 {
  grid-column: 3;
  grid-row: 1 / span 2;
}

.item3 {
  grid-column: 1;
  grid-row: 2 / span 2;
}

.item5 {
  grid-column: 2 / span 2;
  grid-row: 3;
}

.testimonial .bottom_image {
  width: 12.5rem;
}

@media screen and (max-width: 600px) {
  .stories-container {
    margin: 5vw 0;
    height: 200vh !important;
  }

  .stories-container .heading_secondary {
    font-size: 5vw;
    margin: 5vw 0;
  }

  .grid-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .testimonial__review-heading {
    font-size: 1.4rem;
  }

  .testimonial__review-text {
    font-size: 1.1rem;
  }

  .testimonial .name {
    line-height: 1;
    font-family: "Helvetica";
    font-size: 1.6rem !important;
  }

  .testimonial img {
    width: 5rem;
    aspect-ratio: 1;
    border-radius: 50%;
    padding: 2px;
  }

  .testimonial .bottom_image {
    width: 8rem;
    display: none;
  }
}

/* @media screen and (min-width: 33em) {
  .testimonial-grid {
    grid-template-areas: "one one" "two three" "five five" "four four";
  }
}
@media screen and (min-width: 38em) {
  .testimonial-grid {
    grid-template-areas: "one one" "two five" "three five" "four four";
  }
}
@media screen and (min-width: 54em) {
  .testimonial-grid {
    grid-template-areas: "one one two" "five five five" "three four four";
  }
}
@media screen and (min-width: 75em) {
  .testimonial-grid {
    width: 88%;
  } 
} */

/* ANIMATIONS */
@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }
  80% {
    transform: translateX(1rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }
  80% {
    transform: translateX(-1rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInRightDown {
  from {
    opacity: 0;
    transform: translate(-20px, -20px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slideInTop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideInBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
