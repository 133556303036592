.dark {
  box-shadow: 0 2px 8px rgba(0,0,0,0.7);
  border: 2px solid rgba(0,0,0,0.7);
}

.light {
  box-shadow: 0 2px 8px rgba(255,255,255, 0.7);
  border: 2px solid rgba(255,255,255, 0.7);
}

body {
  overflow-x: hidden !important;
}

.home {
  text-decoration: underline;
}/*# sourceMappingURL=home.css.map */

.home_container {
  /* background: linear-gradient(to left, #55c57a, transparent) !important; */
  height: max-content;
  
}

.solid_background{
  position: fixed;
  width: 100%;
  height: 40vh;
  background-image: linear-gradient(to left, rgb(178, 253, 165), rgb(95, 209, 171));;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 40%, 0 24vh);
  clip-path: polygon(0 0, 100% 0, 100% 40%, 0 24vh);
  z-index: 100;
  transition: 0.5s all;
}

.transparent_background {
  position: fixed;
  width: 100%;
  height: 40vh;
  background: transparent;
  z-index: 0;
  transition: 0.5s all;
}

.border-gradient {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
}

.border-gradient-green {
  border-image-source: linear-gradient(to left, #00C853, #B2FF59);
}

.only-bottom {
  border-left: 0;
  border-right: 0;
  border-top: 0;
}

.welcome_message_container {
  text-align: center;
  width: 40%;
  height: max-content;
  margin-top: 15%;
}

.welcome_message {
  font-family: "Adys Regular";
  font-size: 4.6rem;
  font-weight: 400;
  color: #000;
  text-shadow: 1px 0px 1px #000;
  letter-spacing: 0.2rem;
}

.module-selection-message {
  font-family: "Adys Regular";
  font-size: 2.5rem;
  font-weight: 400;
  color: #000;
  text-shadow: 0px 0px 1px #000;
  letter-spacing: 0.2rem;
}

.welcome_message-name {
  font-family: "Adys Bold";
  font-size: 4.7rem;
  font-weight: 600;
  color: rgb(95, 209, 171);
  text-shadow: 1px 1px 1px #000;
}

.home-content {
  display: flex;
  flex-direction: row;
  justify-content: start;
  /* align-items: center; */
  gap: 16%;
}

.module-nodes-container {
  margin-top: 18%;
}

#central-node-container {
  border: 2px dashed rgb(95, 209, 171);
  border-radius: 50%;
  padding: 2.5rem;
  position: absolute;
}

#central-node {
  width: 27rem;
  height: 27rem;
  border: 5px solid rgb(95, 209, 171);
  border-radius: 50%;
  box-shadow: 0 2px 8px rgb(178, 253, 165);
}

.module-container {
  position: relative;
  width: 15rem;
  height: 15rem;
  z-index: 200;
}

.module {
  width: 10rem;
  height: 10rem;
  /* border: 2px solid #55c57a; */
  /* box-shadow: 0 2px 8px #00C853; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 2rem;
  position: relative;
  z-index: 200;

}

.module:hover {
  border: 2px solid rgb(95, 209, 171);
  box-shadow: 0 2px 8px rgb(178, 253, 165);
}


.first {
  right: 10rem;
  top: 1rem;

  transition: 0.5s all;
}

.first:hover {
  right: 8.5rem;
  top: 1.8rem;
}

.second {
  right: 0;
  left: 2.5rem;
  top: 1rem;
  transition: 0.5s all;
}

.second:hover{
  left: 1rem;
  top: 1.8rem;
}

.third {
  top: 10rem;
  right: 11rem;
  transition: 0.5s all;
}

.third:hover{
  right: 9.4rem;
  top: 9.4rem;
}

.fourth {
  top: 11rem;
  left: 2rem;
  transition: 0.5s all;
}

.fourth:hover {
  top: 10rem;
  left: 1rem;
}

.connector-outter {
  width: 2.3rem;
  height: 2.3rem;
  border: 1px solid rgb(95, 209, 171);
  /* border: 1px solid rgba(0,0,0,0.7); */

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.connector-inner {
  width: 1.3rem;
  height: 1.3rem;
  background-color: rgb(95, 209, 171);
  /* background-color: rgba(0,0,0,0.7); */
  border-radius: 50%;
}

.central-node-image-wrap {
  overflow: hidden;
  position: relative;
  width: 22rem;
  height: 22rem;
  margin-left: 2rem;
  margin-top: 1.5rem;
  margin-bottom: 0;
  cursor: pointer;
  transition: all 0.2s;
}

.central-node-image-glitch{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: url('../../../images/paw-glitch.png') no-repeat center center;
  /* -webkit-filter: drop-shadow(2px 2px 2px #fff); */
  /* filter: drop-shadow(3px 3px 3px #fff); */
  background-size: cover;
  transform: scale(1);
}

.central-node-image-glitch:before,
.central-node-image-glitch:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  transform: translateX(2px);
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  
  background-blend-mode: difference;
}

.central-node-image-glitch:before {
  animation-name: glitchAnim1;
}

.central-node-image-glitch:after {
  animation-name: glitchAnim2;
}

.module-icon {
  color: rgba(0, 0, 0, 0.7);
  transition: 0.5s all;
}

.third .module-icon,
.fourth .module-icon {
  color: rgba(0, 0, 0, 0.7);
  transition: 0.5s all;
}

.module:hover .module-icon {
  color: rgb(95, 209, 171) !important;
}

.module-summary {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;
  border-radius: 50%;
  transition: 0.5s all;
}

.reveal {
  animation: reveal 0.6s ease-in-out;
  animation-fill-mode: forwards;
}

.hide {
  animation: hide 0.3s ease-in-out;
  animation-fill-mode: forwards;
}

.module-details-container {
  margin-top: 5rem;
  margin-left: 2rem;
  /* border: 1px solid rgba(0,0,0,0.7); */
  border-radius: 8px;
  /* box-shadow: 0 2px 8px rgba(0,0,0,0.7); */
  padding: 1rem;
  animation: showFromLeft 0.7s ease-in-out;
  animation-fill-mode: forwards;
}

.module-title {
  font-family: "Adys Bold";
  font-size: 3rem;
  color: rgb(95, 209, 171);
  text-shadow: 1px 1px 1px #000;
}

.module-summary-text {
  font-family: "Adys Regular";
  font-size: 1.8rem;
  font-weight: 400;
  color: #000;
  /* text-shadow: 1px 0px 1px #000; */
  letter-spacing: 0.1rem;
  margin-top: 2rem;
}

.module-summary-image-container {
  position: absolute;
  width: 8rem;
  height: 8rem;
  
  display: flex;
  justify-content: center;
  align-items: center; 

  border: 1px solid rgba(0,0,0,0.5);
  box-shadow: 0 0 5px inset rgba(0,0,0,0.5);

  border: 1px solid rgb(95, 209, 171);
  box-shadow: 0 0 5px inset rgb(178, 253, 165);
/*   
  border: 1px solid #55c57a;
  box-shadow: inset 1px 1px 2px 1px #55c57a; */
  border-radius: 50%;

}

.footer-container {
  position:relative;
  z-index: 400;
 }

.big {
  font-size: 4rem;
  margin-left: 9rem;
  margin-top: 1rem;
  width: max-content;
}

/* ANIMATIONS */

#mask {
  position: absolute;
  width: 25rem;
  height: 25rem;
  border-radius: 50%;
  background-color: #fff;
  background: url('../../../images/paw-glitch.png') no-repeat center center;
  background-size: cover;
}

.showFromTop {
  animation: showFromTop 0.7s ease-in-out;
  animation-fill-mode: forwards;
}

.showFromBottom {
  animation: showFromBottom 0.7s ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes glow {
  0% {
      filter: drop-shadow(0 0 0 rgba(95, 209, 171, 1));
  }
  50% {
      filter: drop-shadow(3px 3px 2px rgba(95, 209, 171, 1));
  }
  100% {
      filter: drop-shadow(0 0 0 rgba(95, 209, 171, 1));
  }
}

@keyframes hide {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}


@keyframes reveal {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes showFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-500px)
  }
  100% {
    opacity: 1;
    transform: translateX(50px);
  }
}

@keyframes showFromTop {
  0% {
    opacity: 0;
    transform: translateY(-100px)
  }
  100% {
    opacity: 1;
    transform: translateY(20px);
  }
}

@keyframes showFromBottom {
  0% {
    opacity: 0;
    transform: translateY(500px)
  }
  100% {
    opacity: 1;
    transform: translateX(-50px);
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .solid_background {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 40%, 0 24vh);
    clip-path: polygon(0 0, 100% 0, 100% 40%, 0 24vh);
  }

  .home-content {
    flex-direction: column;
  }

 .module-nodes-container {
    transform: scale(0.65);
    margin-top: -10vh;
    margin-left: 5vw;
    margin-bottom: -20vh;
    z-index: 200;
 }

 .module-selection-message {
    font-size: 1.8rem;
    width: 80%;
    margin: 0 auto;
 }

 .module {
  z-index: 200;

 }

 .welcome_message_container {
    width: 100%;
    margin-top: 12vh;
 }

 .welcome_message {
    font-size: 2.3rem;
 }

 .welcome_message-name {
    font-size: 2.5rem;
 }

 .footer-container {
  position:relative;
  z-index: 400;
  /* background-image: linear-gradient(to left, rgba(178, 253, 165, 1), rgba(95, 209, 171, 1)); */
 }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}




