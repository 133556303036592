.u-center-text {
    text-align: center;
}

.u-margin-bottom-small {
    margin-bottom: 2rem;
}

.u-margin-bottom-medium {
    margin-bottom: 2rem;
}

.u-margin-bottom-big {
    margin-bottom: 8rem;
}