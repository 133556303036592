.home_glitch-text_wrap{
    overflow: hidden;
    position: relative;
    letter-spacing: 0.5rem;
    margin-bottom: 0;
  
    width: 25%;
    height: 5.4rem;
    margin-top: 1vw;
    margin-left: -10rem;
}
  
.home_glitch-text-dark,
.home_glitch-text-light {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
  
    display: block;
    letter-spacing: 0.5rem;
  
    font-weight: 400;
    animation: moveInLeft 1s ease-out;

    font-family: "Adys Bold", sans-serif;
    background: -webkit-linear-gradient(#282D37, #797C7D);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;    
    font-size: 3rem;
    width: max-content;
    margin: 0;
    margin-left: 2.4vw;
}

.home_glitch-text-light,
.beta-text-light{
    background: -webkit-linear-gradient(rgba(255,255,255, 1), rgba(255,255, 255, 1));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;  
}
  
.home_glitch-text-dark:before,
.home_glitch-text-dark:after {
    content: attr(data-text);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: inherit;
    transform: translateX(2px);
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    
    background-blend-mode: difference;
}

.home_glitch-text-light:before,
.home_glitch-text-light:after {
    content: attr(data-text);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: inherit;
    transform: translateX(2px);
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    
    background-blend-mode: difference;
}

.beta-text-dark{
    font-family: "Adys Regular", sans-serif;
    font-size: 2rem;
    background: -webkit-linear-gradient(#282D37, #797C7D);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: 0.7rem;  
}

.beta-text-light{
    font-family: "Adys Regular", sans-serif;
    font-size: 2rem;
    margin-left: 0.7rem;  
}
  
.fast-anim:before, 
.fast-anim:after{
    animation-duration: 4s;
    transform: translateX(2px);
}
  
.slow-anim:before, 
.slow-anim:after{
    animation-duration: 5s;
    transform: translateX(1px);
}
  
.home_glitch-text-dark:before {
    animation-name: glitchAnim1;
}

.home_glitch-text-light:before {
    animation-name: glitchAnim1;
}
  
.home_glitch-text-dark:after {
    animation-name: glitchAnim2;
}

.home_glitch-text-light:after {
    animation-name: glitchAnim2;
}
  
.home_glitch_wrap {
    overflow: hidden;
    position: relative;
    width: 6.4rem;
    height: 6.4rem;
  
    margin-bottom: 0;
    cursor: pointer;
    transition: all 0.2s;
    animation: moveInBottom 1s ease-out;
}
  
.home_glitch-dark{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: url('../../images/paw-glitch.png') no-repeat center center;
    /* -webkit-filter: drop-shadow(2px 2px 2px #fff); */
    /* filter: drop-shadow(3px 3px 3px #fff); */
    background-size: cover;
    transform: scale(1);
}
  
.home_glitch-dark:before,
.home_glitch-dark:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: inherit;
    transform: translateX(2px);
    animation-duration: 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    
    background-blend-mode: difference;
}
  
.home_glitch-dark:before {
    animation-name: glitchAnim1;
}
  
.home_glitch-dark:after {
    animation-name: glitchAnim2;
}

.home_glitch-light{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: url('../../assets/paw-white.png') no-repeat center center;
    /* -webkit-filter: drop-shadow(2px 2px 2px #fff); */
    /* filter: drop-shadow(3px 3px 3px #fff); */
    background-size: cover;
    transform: scale(1);
}
  
.home_glitch-light:before,
.home_glitch-light:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: inherit;
    transform: translateX(2px);
    animation-duration: 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    
    background-blend-mode: difference;
}
  
.home_glitch-light:before {
    animation-name: glitchAnim1;
}
  
.home_glitch-light:after {
    animation-name: glitchAnim2;
}

.MuiBox-root {
    padding: 1rem 2%;
}

.home_nav-text {
    font-family: "Adys Bold", sans-serif;
    font-size: 2rem;
    width: fit-content;
    cursor: pointer;
    font-weight: 400;
    z-index: 10;
    transition: all 0.3s;
}

.home_nav-text:hover {
    text-decoration: underline;
    text-underline-offset: 5px;
   /*  text-decoration-color: #55c57a;
    color:#55c57a; */

    text-decoration-color: #fff;
    color: #fff;
}

.nav-search-field {
    width: 20rem;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    border-radius: 5px;
    border: 1.5px solid #000;
    height: 3.4rem;
    padding: 1rem;
}

.navigation-item {
    cursor: pointer;
}

.navigation-item:hover p {
    transition: all 0.3s;
    color: #fff;
}

.login-icon {
    transition: all 0.3s;
}

.navigation-item:hover .login-icon {
    transition: all 0.3s;
    color: #fff;
}

/* .navigation-search-container .MuiFormControl-root .MuiInputLabel-animated {
    font-size: 1.4rem;
}

.navigation-search-container .MuiFormControl-root .MuiOutlinedInput-input {
    font-size: 1.4rem;
}

.navigation-search-container .MuiFormControl-root .MuiOutlinedInput-root {
    transform: scaleY(0.8);
}
 */



