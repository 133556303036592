.accordion_cont {
    margin-top: -10.7vw;

}

.faq-container {
    width: 80vw;
    margin: 0 auto;
    border-top: 1px solid #838181;
}

.faq-container .heading-secondary {
    font-family: "Adys Bold", sans-serif;
}

.accordions-container {
    transition: all 0.5s;
}

.hide-left{
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-100%);
    transition: all 0.8;
}

.show {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
}

@media screen and (max-width: 430px) {
    .faq-container {
        width: 80%;
        margin: 0 auto;
        margin-top: 15vw;
        padding: 5vw 0;
    }

    .faq-container .u-margin-bottom-big {
        margin-bottom: 5vw;
        width: 100%;
    }

    .faq-container .u-margin-bottom-big .heading-secondary{
        width: 100%;
    }
}