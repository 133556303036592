* {
    font-family: "Ubuntu";
    font-weight: 400;
}

.result_card-containter {
    width: 25vw;
    height: auto;
    margin-bottom: 1vw;
    border-radius: 25px;
    background-color: #fff;
    box-shadow: 0 1px 2px #0000004d, 0 1px 10px #00000038;

    padding: 0.6vw 0;
    cursor: pointer;
    transition: all 0.5s;
}

.result_card-containter:hover{
    transition: all 0.5s;
    transform: scale(1.05);
}

.result_card-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 0 1rem;
}

.result_card-top_container {
    width: 100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.result_card-dog_image {
    width: 30%;
    border-radius: 8px;
}


.result_card-separator-line {
    display: block;
    width: 80%;

    height: 1px;
    background-image: linear-gradient(to left, rgba(126, 213, 111, 0.2), rgba(40, 180, 133, 0.7));
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}


.result_card-dog_details_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;
   
}

.result_card-dog_details_header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    text-align: center;
}

.result_card-dog_details_header1 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    text-align: center;
}

.result_card-dog_details_header .dog-name {
    font-size: 2.3rem;
    font-family: "Adys Bold",  sans-serif;
    color: rgba(95, 209, 171);
    font-weight: 700;
    margin-right: 0.2rem;
}

.result_card-dog_details_header .dog-breed {
    font-family: "Adys Regular";
    color: rgba(0,0,0,0.6);
    font-size: 1.6rem;
}

.result_card-dog_details_container .main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 0;
}

.result_card-dog_details_container .dog-sex,
.result_card-dog_details_container .dog-address,
.result_card-dog_details_container .dog-color,
.result_card-dog_details_container .weight,
.result_card-dog_details_container .age
 {
    font-family: "Adys Regular", sans-serif;
    font-size: 1.5rem;
}

.result_card-dog_details_container .dog-color {
}

.color-indicator {
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    margin-left: 0.4rem;
    margin-top: -0.5rem;
}

.info-row-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.2rem;
}

.checbox-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
}

.result_card-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-left: 2rem
}

.result_card-bottom_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5vw;
    margin-top: 1rem;
}

.favourites_icon {
    cursor: pointer;
    border-radius: 50%; 
    padding: 4px;
    transition: 0.3s all;
}

.favourites_icon:hover {
    background-color: rgba(0,0,0,0.05);
}


@media screen and (max-width: 430px) {
    .result_card-containter {
        width: 90%;
        height: auto;
        margin-bottom: 1vw;
        padding: 1vw 0;
    }

    .result_card-dog_image {
        width: 3rem;
        border-radius: 8px;
    }

    .result_card-dog_details_header .dog-name {
        font-size: 2rem;
        font-family: "Adys Bold", sans-serif;
        color: rgba(95, 209, 171);
        margin-right: 0.2rem;
    }
    
    .result_card-dog_details_header .dog-breed {
        font-family: "Adys Regular", sans-serif;
        color: rgba(0,0,0,0.6);
        font-size: 1.4rem;
    }

    .result_card-dog_details_container .dog-sex,
    .result_card-dog_details_container .dog-address,
    .result_card-dog_details_container .dog-color,
    .result_card-dog_details_container .weight,
    .result_card-dog_details_container .age
    {
    
        font-size: 1.35rem;
    }

    .info-row-container .color-indicator {
        width: 1.4rem;
        height: 1.4rem;
        margin-left: 0.1rem;
        margin-top: -0.5rem;
    }
}